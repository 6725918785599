import React, { useState } from 'react';
import Header from '../Header/Header';
import AppSidebar from '../AppSidebar';
import MyMapComponent from '../Map/MyMapComponent'; // Revisa el nombre del componente

function Map({profile, setProfile}) {
  // const [profile, setProfile] = useState(localStorage.getItem('name') || '');

  return (
    <div>
      <AppSidebar setProfile={setProfile}/>
      <div className="wrapper d-flex flex-column min-vh-100">
        {/* <Header profile={profile} />  */}
        <div className="body flex-grow-1">
          {/* <AppContent /> */}
          {/* Renderiza MyMapComponent */}
          <MyMapComponent profile={profile} setProfile={setProfile} /> 
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  );
}

export default Map;