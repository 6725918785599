// import React, { useState }   from 'react';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import Login from './components/Login/Login';
// import Home from './components/Home/Home';
// import Dashboard from './components/Dashboard/Dashboard';
// import Header from './components/Header/Header';
// import useToken from './components/App/useToken';
// import ProtectedRoute from '../src/components/ProtectedRoute/ProtectedRoute'; // Importa el componente de 

// function App() {

//   const [isLoggedIn, setIsLoggedIn] = useState(false); // Estado para guardar si el usuario está logeado
//   const {token, setToken } = useToken();
//   const [profile, setProfile] = useState(localStorage.getItem('name'));

//   const handleLogin = (token, profile) => {
//     // Actualiza el estado cuando el usuario inicia sesión
//     setIsLoggedIn(true);
//     // ...  (Puedes hacer algo con el token y el perfil)
//   };
//   console.log('token ' + token);
//   if (!token) {

//   return (
//     <BrowserRouter>
//       <Routes>
        
//       <Route path="/login" element={<Login setToken={setToken} setProfile={setProfile} />}  />
//       <Route path="/" element={<Home />} />
      
        
//         {/* <Route path="/dashboard" element={<ProtectedRoute isLoggedIn={isLoggedIn}>
//           <Route path="/dashboard" element={<Dashboard />} />
//         </ProtectedRoute>} /> */}
        
//       </Routes>
//     </BrowserRouter>
//   );


// } else {

//   return (
//     // <Header profile={profile}></Header>
//     <BrowserRouter>
//       <Routes>
        
      
//       {/* <Route path="/dashboard" element={<ProtectedRoute isLoggedIn={isLoggedIn}> */}
//         <Route path="/dashboard" element={<Dashboard />} />
//         {/* </ProtectedRoute>} /> */}
//         <Route path="/login" element={<Login setToken={setToken} setProfile={setProfile} />}  />
//         <Route path="/" element={<Home />} />
        
//       </Routes>
//     </BrowserRouter>
//   );

// }
// }

// export default App;

import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './components/Dashboard/Dashboard';
import Inventory from './components/Inventory/Inventory';
import Map from './components/Map/Map';
import Login from './components/Login/Login';
import Register from './components/Register/Register'
import useToken from './components/App/useToken';
import Header from './components/Header/Header';
import Home from './components/Home/Home';
import NewProjectForm from './components/NewProject/NewProjectForm'
import ProjectFormEdit from './components/NewProject/ProjectFormEdit'
import InsertVideo from './components/InsertVideo/InsertVideo'
import NewUser from './components/NewUser/NewUser'
import Gallery from './components/Gallery/Gallery'
import ProfileUser from './components/ProfileUser/ProfileUser'
import NewProjectComponet from './components/NewProject/NewProjectComponent';

function App() {
  const { token, setToken } = useToken();
  const [profile, setProfile] = useState(null);

  return (
    <div className="Wrapper">
      {/* <Header profile={profile} /> */}
      <div className="content-wrapper"></div>
      <Routes> 
        {/* El usuario está autenticado */}
        {token ? ( 
              <>
              {/* <Route path="/home" element={<Home />} /> */}
              <Route path="/new-project" element={<NewProjectComponet />} />
              <Route path="/new-project/form" element={<NewProjectForm profile={profile} setProfile={setProfile} />} />
              <Route path="/project/form-edit/:id" element={<ProjectFormEdit profile={profile} setProfile={setProfile} />} />
              <Route path="/new-user/form" element={<NewUser profile={profile} setProfile={setProfile} />} />
              <Route path="/new-video/form" element={<InsertVideo profile={profile} setProfile={setProfile} />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/profile/user" element={<ProfileUser setToken={setToken} profile={profile} setProfile={setProfile} />} />
              <Route path="/dashboard" element={<Dashboard profile={profile} setProfile={setProfile} />} />
              <Route path="/inventory" element={<Inventory />} />
              <Route path="/map" element={<Map profile={profile} setProfile={setProfile} />} />
              <Route path="/login" element={<Navigate to="/dashboard" replace  />} />
              <Route path="/" element={<Navigate to="/dashboard" replace  />} />
              <Route path="/register" element={<Navigate to="/dashboard" replace  />} />
              </>
        ) : ( 
          <>
          <Route path="/home" element={<Navigate to="/login" replace />} />
          <Route path="/new-project" element={<Navigate to="/login" replace />} />
          <Route path="/new-project/form" element={<Navigate to="/login" replace />} />
          <Route path="/project/form-edit" element={< Navigate to="/login" replace />} />
          <Route path="/new-user/form" element={<Navigate to="/login" replace />} />
          <Route path="/new-video/form" element={<Navigate to="/login" replace />} />
          <Route path="/gallery" element={<Navigate to="/login" replace />} />
          <Route path="/profile/user" element={<Navigate to="/login" replace />} />
          <Route path="/dashboard" element={<Navigate to="/login" replace />} />
          <Route path="/inventory" element={<Navigate to="/login" replace />} />
          <Route path="/map" element={<Navigate to="/login" replace />} />
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login setToken={setToken} setProfile={setProfile} />} /> 
          <Route path="/register" element={<Register setToken={setToken} setProfile={setProfile} />} /> 
            
          </> 
        )}
      </Routes>
    </div> 
  ); 
}

export default App;