// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import { Provider } from 'react-redux'
// import reportWebVitals from './reportWebVitals';
// import store from './store'
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// // import Routes from './components/AppRoutes'; // Definición de tus rutas
// import AppRoutes from '../src/routes'; // Definición de tus rutas
// import Dashboard from './components/Dashboard/Dashboard';
// // import dotenv from 'dotenv';

// // dotenv.config(); // Carga las variables de entorno desde .env

// const root = ReactDOM.createRoot(document.getElementById('root'));
// console.log('test')
// root.render(
//   <Provider store={store}>
//   <React.StrictMode>
//   <BrowserRouter> {/* Envolver con BrowserRouter */}
//   {/* <Routes element={<AppRoutes />} />  */}
//   <Routes element={<App />} /> {/* Asegúrate de que App esté aquí */}
//   </BrowserRouter>
//   </React.StrictMode>
//   </Provider>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; 
import AppRoutes from './routes'; // Importar AppRoutes
import Login from './components/Login/Login';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        
          {/* <Route path='/login' element={<Login/>}/>  */}
          <App /> 
        
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);
