import React, { useState } from 'react';
import Header from '../Header/Header';
import AppSidebar from '../AppSidebar';
import Widgets from '../widgets/Widgets';
import MyMapComponent from '../Map/MyMapComponent'; // Revisa el nombre del componente

function Inventory() {
  const [profile, setProfile] = useState(localStorage.getItem('name') || '');

  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100">
        <Header profile={profile} /> 
        <div className="body flex-grow-1">
          
          <Widgets /> 
        </div>
        
      </div>
    </div>
  );
}

export default Inventory;