import React, { useState } from 'react';
import Header from '../Header/Header';
import AppSidebar from '../AppSidebar';
// import Widgets from '../widgets/Widgets';
import MyMapComponent from '../Map/MyMapComponent'; // Revisa el nombre del componente

import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardGroup,
  CCardHeader,
  CCardImage,
  CCardLink,
  CCardSubtitle,
  CCardText,
  CCardTitle,
  CListGroup,
  CListGroupItem,
  CCol,
  CRow,
} from '@coreui/react'

import ImagenColor from '../../assets/images/react.jpg'

function NewProject() {
  const [profile, setProfile] = useState(localStorage.getItem('name') || '');

  return (
    <div>
      <CRow className='image-container'>
       <CCol sm={3}>
      
      <CCard style={{ width: '18rem' }}>
        <CCardImage orientation="top" src={ImagenColor} />
        <CCardBody>
          {/* <CCardTitle>Analyze Signals</CCardTitle> */}
          {/* <CCardText>
            Some quick example text to build on the card title and make up the bulk of the card's content.
          </CCardText> */}
          <CButton color="primary" className='btn-primary-orange' href="/new-project/form">Analyze Signals
          </CButton>
        </CCardBody>
      </CCard>
      
      </CCol>
      <CCol sm={3}>
      
      <CCard style={{ width: '18rem' }}>
        <CCardImage orientation="top" src={ImagenColor} />
        <CCardBody>
          {/* <CCardTitle>Analyze Cracks</CCardTitle> */}
          {/* <CCardText>
            Some quick example text to build on the card title and make up the bulk of the card's content.
          </CCardText> */}
          <CButton color="primary" className='btn-primary-orange' href="/new-project/form">Analyze Cracks</CButton>
        </CCardBody>
      </CCard>
      
      </CCol>
      <CCol sm={3}>
      
      <CCard style={{ width: '18rem' }}>
        <CCardImage orientation="top" src={ImagenColor} />
        <CCardBody>
          {/* <CCardTitle>Project Gallery</CCardTitle> */}
          {/* <CCardText>
            Some quick example text to build on the card title and make up the bulk of the card's content.
          </CCardText> */}
          <CButton color="primary" className='btn-primary-orange' href="/inventory">Project Edit
          </CButton>
        </CCardBody>
      </CCard>
      
      </CCol>
      </CRow>
    </div>
  );
}

export default NewProject;