import React, { useState } from 'react';
import Header from '../Header/Header';
import AppSidebar from '../AppSidebar';
// import Widgets from '../widgets/Widgets';
import MyMapComponent from '../Map/MyMapComponent'; // Revisa el nombre del componente
import FormUser from '../Form/FormUser';

import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardGroup,
  CCardHeader,
  CCardImage,
  CCardLink,
  CCardSubtitle,
  CCardText,
  CCardTitle,
  CListGroup,
  CListGroupItem,
  CCol,
  CRow,
} from '@coreui/react'

function NewUser({ profile, setProfile }) {
  // const [profile, setProfile] = useState(localStorage.getItem('name') || '');

  return (
    <div>
      <AppSidebar setProfile={setProfile} />
      <div className="wrapper d-flex flex-column min-vh-100">
        {/* <Header profile={profile} />  */}
        <div className="body flex-grow-1">
          <FormUser setProfile={setProfile} /> 
        </div>
      </div>
    </div>
  );
}

export default NewUser;