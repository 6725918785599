import React from 'react'
import {
  CCard,
  CCardBody,

} from '@coreui/react'

import { DocsExample } from '../../components'


const Widgets = ({ data }) => {
  const random = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

  return (
    <CCard className="">
      
      <CCardBody className='quitar-padding'>
        
        <DocsExample data={data} href="components/widgets/#cwidgetstatsd">

        </DocsExample >
        
      </CCardBody>
    </CCard>
  )
}

export default Widgets
