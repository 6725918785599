import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../../scss/style.scss'

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CForm,
  CFormLabel,
  CFormInput,
  CFormSelect,
  CTable,
  CTableRow,
  CTableHeaderCell,
  CToast,
  CToastBody,
  CToastClose,
  CSpinner,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

import {
  cilCheck,
  cilMedicalCross,
  cilArrowLeft,
} from '@coreui/icons'

import iconImagenUpload from '../../assets/images/icon-imagen-upload.png'
import trash from '../../assets/images/trash.png'
import movie from '../../assets/images/movie.png'
import iconCheck from '../../assets/images/check.png'

async function senData(credentials) {
  //   return fetch(process.env.REACT_APP_URL_API+'/api/auth/login', {
  return fetch(`${process.env.REACT_APP_API_BASE_URL}/api/video/send-data`, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

function FormInsert({ profile, setProfile }) {
  // const [profile, setProfile] = useState(localStorage.getItem('name') || '');
  const [videoFile, setVideoFile] = useState(null);
  const [kminicio, setKminicio] = useState(null);
  const [kmfin, setKmfin] = useState(null);
  const [tipoCamara, setTipoCamara] = useState(null);
  const [segmento, setSegmento] = useState(null);
  const [visible, setVisible] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null); // Referencia al input de archivo
  const fileNameRef = useRef(null); // Referencia al elemento donde mostrar el nombre
  const referenciaEntradaArchivo = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dataProyecto, setDataProyecto] = useState(null);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [formularios, setFormularios] = useState([]);

  const handleGoBack = () => {
    navigate(-1); // Navega a la página anterior
  };

  const actualizarFormulario = (formularioId, nuevoVideo) => {
    setFormularios(formularios.map(form => {
      if (form.id === formularioId) {
        return {
          ...form,
          videos: [...form.videos, nuevoVideo] // Agrega el nuevo video al array de videos
        };
      }
      return form;
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const formularioId = e.target.getAttribute('data-formulario-id');
    const videoId = e.target.getAttribute('data-video-id');

    if (file && formularioId) {
      const nuevoVideo = { id: Date.now(), file: file };
      actualizarFormulario(parseInt(formularioId, 10), nuevoVideo);
    }
  };

  const handleSelectImage = (formularioId) => {
    const input = fileInputRef.current;
    input.setAttribute('data-formulario-id', formularioId); // Agrega un atributo al input
    input.click();
  };

  const handleRemoveImage = (formularioId, videoIndex) => {
    setFormularios(formularios.map(form => {
      if (form.id === formularioId) {
        const updatedVideos = [...form.videos];
        updatedVideos.splice(videoIndex, 1);
        return { ...form, videos: updatedVideos };
      }
      return form;
    }));
  };

  const handleCloseToast = (element) => {
    setShowSuccessToast(false);
  }

  useEffect(() => {
    // const handleScroll = () => {
    //   setShowFooter(window.scrollY > 100); // Ajusta el valor de desplazamiento para mostrar el pie de página
    // };

    // window.addEventListener('scroll', handleScroll);
    // return () => window.removeEventListener('scroll', handleScroll);
    if (fileNameRef.current && selectedImage) {
      fileNameRef.current.textContent = selectedImage.name;
    }
  }, [selectedImage]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Muestra un indicador de carga mientras se realiza la solicitud

      try {
        const tokenData = localStorage.getItem('token'); // Obtén el token desde el localStorage
        const token = tokenData ? JSON.parse(tokenData).token : null; // Parsear el token JSON

        if (!token) {
          throw new Error('Token no disponible en localStorage'); // Manejar el caso en el que no haya token
        }

        // Realiza la solicitud a la API con el token en los headers
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/segmento-list/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        // Si la respuesta no es correcta, lanza un error
        if (!response.ok) {
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }

        // Obtén los datos JSON de la respuesta
        const jsonData = await response.json();
        console.log('Datos JSON recibidos:', jsonData); // Imprime los datos recibidos

        // Actualiza el estado de dataProyecto con los datos recibidos
        setDataProyecto(jsonData);

        // Guarda los datos en el localStorage si es necesario
        localStorage.setItem('data-segmento', JSON.stringify(jsonData));

      } catch (error) {
        console.error('Error durante la solicitud:', error); // Manejo de errores
        setError(error); // Guarda el error en el estado para mostrarlo en la interfaz
      } finally {
        setIsLoading(false); // Detén el indicador de carga
      }
    };

    fetchData(); // Llama a la función fetchData cuando el componente se monte o cambie el id

    // Este useEffect se ejecutará siempre que cambie `id` y asegurará que los datos se recarguen
  }, [id]);

  useEffect(() => {
    setFormularios([
      {
        id: Date.now(),
        videos: [],
        kminicio: '',
        kmfin: '',
        tipoCamara: '',
        segmento: ''
      }
    ]);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!selectedImage) return;

    const formData = new FormData();
    formData.append('video', selectedImage); // Nombre del campo 'video'
    formData.append('kminicio', kminicio);
    formData.append('kmfin', kmfin);
    formData.append('tipoCamara', tipoCamara);
    formData.append('segmento', segmento);
    formData.append('id', id);

    try {
      const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
      // console.log(storedName)
      const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
      // console.log(token)
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/video/send-data`, formData, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + token
        }
      });

      console.log('Respuesta del servidor:', response.data);
      setShowSuccessToast(true);
    } catch (error) {
      console.error('Error al subir el video:', error);
    } finally {
      setIsLoading(false); // Ocultar el spinner al finalizar la carga
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    // Envía los datos de cada formulario
    for (const formulario of formularios) {
      try {
        const formData = new FormData();
        formData.append('kminicio', formulario.kminicio);
        formData.append('kmfin', formulario.kmfin);
        formData.append('tipoCamara', formulario.tipoCamara);
        formData.append('segmento', formulario.segmento);
        formData.append('id', id);

        // Agrega cada video del formulario al FormData
        formulario.videos.forEach(video => {
          formData.append('video', video.file); // Nombre del campo 'videos'
        });

        const tokenData = localStorage.getItem('token');
        const token = tokenData ? JSON.parse(tokenData).token : null;

        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/video/send-data`, formData, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer ' + token
          }
        });

        // Procesar la respuesta de la API
        if (response.status === 200) { // Verifica si la respuesta es exitosa
          console.log(response);
          // const data = await response.json(); // Convierte el cuerpo de la respuesta a JSON
          // console.log('Respuesta del servidor:', data);
          setShowSuccessToast(true);
        } else {
          console.error('Error:', response.status);
          // Maneja el error, por ejemplo, muestra un mensaje de error al usuario
        }

      } catch (error) {
        console.error('Error al subir el video:', error);
      } finally {
        setIsLoading(false); // Ocultar el spinner al finalizar la carga
      }
    }
  };

  const agregarFormulario = () => {
    setFormularios([...formularios, {
      id: Date.now(), // ID único para cada formulario
      videos: [], // Array para los videos del formulario
      kminicio: '',
      kmfin: '',
      longitud: '',
      tipoCamara: '',
      segmento: ''
    }]);
  };


  return (
    <>
      <CRow className='margin-row-contenedor'>
        <CCol xs>
          <CCol xs='12' className='d-flex align-items-center padding-titulo-list-contenedor'>
            <CIcon icon={cilArrowLeft} size="xxl" className="height-auto icon-background-orange" style={{ '--ci-primary-color': 'white' }} /><h5 className='margin-bottom-h5'><strong>Nuevo Proyecto</strong></h5>
          </CCol>
          <CCol xs={10} style={{ 'height': '29rem' }}>
            <CCard className="mb-4 scroll-contenedor-new-proyect" style={{ 'height': '500px' }}>
              <CCol xs={10} className='titulo-formulario-new-video'>Proyecto ID # {id}</CCol>
              <CCardBody>

                <CForm onSubmit={handleSubmitForm}>
                  {formularios.map((form, index) => (
                    <div key={form.id}>
                      <h5 className='letra-contenido-2'>Formulario {index + 1}</h5>

                      <div className="mb-3">
                        <CRow>
                          <CCol xs={12}>
                            <CFormLabel className='letra-titulo-1' htmlFor="anchoCarril">Carga de Videos</CFormLabel>
                          </CCol>
                        </CRow>
                        <CCol xs={10}>
                          <CFormLabel className='letra-contenido-2' htmlFor="Video">Video</CFormLabel>
                          <CCol xs='12' className='quitar-padding'>
                            <CCard className='container-upload-video'>
                              <div className="image-uploader-form-video">
                                <CFormInput
                                  type="file"
                                  accept="video/mp4,video/webm,video/ogg,audio/mpeg,audio/wav"
                                  onChange={(e) => handleFileChange(e, form.id)}
                                  className="file-input"
                                  ref={fileInputRef}
                                />
                                <CButton className="btn-form-video" onClick={() => handleSelectImage(form.id)} ><img src={iconImagenUpload} alt="Selected Image" className="avatar2-video" /></CButton>
                                <span className='letra-contenido'>Arrastra aquí tus videos para subir</span>
                                <span className='letra-contenido'>Formatos MP3, AVI, MOV</span>
                              </div>
                            </CCard>
                          </CCol>
                        </CCol>
                      </div>

                      {/* Resto del formulario */}
                      <div className="mb-3 letra-contenido-2">
                        <CRow>
                          <CCol xs={5}>
                            <CFormLabel htmlFor="kminicio">KM Inicio</CFormLabel>
                            <CFormInput className='letra-contenido-modal-input' size="sm" id="kminicio" placeholder="Ingrese kilometros" value={form.kminicio} onChange={(e) => setFormularios(formularios.map(f => f.id === form.id ? { ...f, kminicio: e.target.value } : f))} />
                          </CCol>

                          <CCol xs={5}>
                            <CFormLabel htmlFor="kmfin">KM Fin</CFormLabel>
                            <CFormInput className='letra-contenido-modal-input' size="sm" id="kmfin" placeholder="Ingrese kilometros" value={form.kmfin} onChange={(e) => setFormularios(formularios.map(f => f.id === form.id ? { ...f, kmfin: e.target.value } : f))} />
                          </CCol>
                        </CRow>
                      </div>

                      <div className="mb-3 letra-contenido-2">
                        <CRow>
                          <CCol xs={5}>
                            <CFormSelect size="sm"
                              className='letra-contenido-modal-input'
                              label="Tipo de Cámara"
                              onChange={(e) => setFormularios(formularios.map(f => f.id === form.id ? { ...f, tipoCamara: e.target.value } : f))}>
                              <option className='fuente-letra-oscura' >Seleccione tipo</option>
                              <option className='fuente-letra-oscura' value="frontal">Frontal</option>
                              <option className='fuente-letra-oscura' value="cenital">Cenital</option>
                            </CFormSelect>
                          </CCol>
                          <CCol xs={5}>
                            <CFormSelect size="sm"
                              className='letra-contenido-modal-input'
                              label="Segmento"
                              onChange={(e) => setFormularios(formularios.map(f => f.id === form.id ? { ...f, segmento: e.target.value } : f))}>
                              {/* Opción por defecto */}
                              <option className='fuente-letra-oscura' value="">Seleccione Segmento</option>

                              {/* Renderizar opciones del array */}
                              {dataProyecto && dataProyecto.map((segmento) => (
                                <option
                                  key={segmento.id}
                                  className='fuente-letra-oscura'
                                  value={segmento.id}
                                >
                                  {segmento.nombre}
                                </option>
                              ))}
                            </CFormSelect>
                          </CCol>
                        </CRow>
                      </div>

                      <div className="mb-3 letra-contenido-2">
                        <CRow>
                          <CCol xs={10}>
                            <CTable bordered small className='table-spam-movie'>
                              <CTableRow>
                                {form.videos.map((video, videoIndex) => (
                                  <CTableHeaderCell
                                    key={video.id} // Agrega un key único a cada fila de la tabla
                                    id="fileName"
                                    className='letra-contenido'
                                    scope="col"
                                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                                  >
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <CButton className='btn-movie-nf' size="sm">
                                        <img src={movie} alt="Descripción de la imagen" className="nav-icon width-img-search" />
                                      </CButton>
                                      <span className='spam-file-movie'>{video.file.name}</span>
                                    </div>
                                    <CButton variant="outline-danger" size="sm" onClick={() => handleRemoveImage(form.id, videoIndex)}>
                                      <img src={trash} alt="Descripción de la imagen" className="nav-icon width-img-search" />
                                    </CButton>
                                  </CTableHeaderCell>
                                ))}
                              </CTableRow>

                            </CTable>
                          </CCol>
                        </CRow>
                      </div>



                    </div>
                  ))}
                  {/* Boton para agregar nuevo formulario */}
                  <div className="mb-3">
                    <CRow className='delete-border-color'>
                      <CCol xs={10}>
                        <CCard className="mt-3 delete-border-color" >
                          <CRow>
                            <div className="mb-3 letra-btn-agregar">
                              <CRow>
                                <CCol xs={12}>
                                  <CButton className="letra-btn-agregar align-inicio" onClick={agregarFormulario}>
                                    <span className='align-inicio'><img src={iconImagenUpload} alt="Selected Image" className="icon-add-form mr-2" /> Agregar Video</span>
                                  </CButton>
                                </CCol>
                              </CRow>
                            </div>
                          </CRow>
                        </CCard>
                      </CCol>
                    </CRow>
                  </div>
                  {/* <CButton type="submit" className="btn-submit-form">
        Guardar
      </CButton> */}
                </CForm>

              </CCardBody>
            </CCard>

          </CCol>
        </CCol>
      </CRow>
      {profile === 'true' ? (
        <footer className='footer-form-video-estilo'>
          <CRow>
            {/* <CCol xs='2'></CCol>  */}
            <CCol xs='12'>
              <CRow>
                <CCol xs='8' className='footer-text-save-leave letra-contenido-2'><CButton onClick={handleSubmitForm} className="px-8 btn-guardar-footer" type="submit"><img src={iconCheck} alt="Selected Image" className="icon-check" /><span className='padding-separar-boton'>Guardar y salir</span></CButton></CCol>
                <CCol xs='1'>
                  <div className="">
                    <CButton onClick={handleGoBack} className="px-8 btn-salir-footer" type="submit">Cancelar</CButton>
                  </div>
                </CCol>
                <CCol xs='1' className='padding-left-footer-next'>
                  <div className="">
                    <CButton onClick={handleSubmit} className="px-8 btn-next-footer" type="submit">Procesar</CButton>

                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </footer>
      ) : (
        <footer className='footer-form-video-estilo'>
          <CRow>
            <CCol xs='2'></CCol>
            <CCol xs='10'>
              <CRow>
                <CCol xs='8' className='footer-text-save-leave letra-contenido-2'><CButton onClick={handleSubmitForm} className="px-8 btn-guardar-footer" type="submit"><img src={iconCheck} alt="Selected Image" className="icon-check" /><span className='padding-separar-boton'>Guardar y salir</span></CButton></CCol>
                <CCol xs='1'>
                  <div className="">
                    <CButton onClick={handleGoBack} className="px-8 btn-salir-footer" type="submit">Cancelar</CButton>
                  </div>
                </CCol>
                <CCol xs='1' className='padding-left-footer-next'>
                  <div className="">
                    <CButton onClick={handleSubmit} className="px-8 btn-next-footer" type="submit">Procesar</CButton>

                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </footer>
      )}
      {showSuccessToast && (
        <CToast
          autohide={false}
          visible={true}
          // color="primary" 
          className="text-white align-items-center backgroundcolor-toast-exito"
          // Estilo para posicionar el toast
          style={{ position: 'fixed', top: '10px', right: '10px', zIndex: 1000 }}
        >
          <div className="d-flex">
            <CToastBody>Video Subido correctamente.</CToastBody>
            <CToastClose onClick={handleCloseToast} className="me-2 m-auto" white />
          </div>
        </CToast>
      )}

      {isLoading && ( // Mostrar el spinner solo si isLoading es true
        <div className="loading-overlay">
          <CSpinner color="primary" />
        </div>
      )}
    </>
  );
}

FormInsert.propTypes = {
  setProfile: PropTypes.func.isRequired

}

export default FormInsert;