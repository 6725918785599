import React, { useState } from 'react';
import Header from '../Header/Header';
import AppSidebar from '../AppSidebar';
// import Widgets from '../widgets/Widgets';
import MyMapComponent from '../Map/MyMapComponent'; // Revisa el nombre del componente
import FormVideo from '../Form/FormVideo';

import {
  CButton,
} from '@coreui/react'

function NewProjectForm({ profile, setProfile }) {
  const [showLogo, setShowLogo] = useState(localStorage.getItem('showLogo') === 'true');
  // const [profile, setProfile] = useState(localStorage.getItem('name') || ''); 

  return (
      <div>
        <AppSidebar setProfile={setProfile} />
        <div className="wrapper d-flex flex-column min-vh-100">
          {/* <Header profile={profile} />  */}
          <div className="body flex-grow-1">
            <FormVideo profile={profile} setProfile={setProfile} /> 
          </div>
        </div>
      </div>
  );
}

export default NewProjectForm;