import React, { useState, useEffect, useRef } from 'react';
import { useNavigate  } from 'react-router-dom';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import axios from 'axios';
import '../../scss/style.scss'
// import Widgets from '../widgets/Widgets';
// import DocsExample from '../DocsExample'

import MyMapComponent from '../Map/MyMapComponent'; // Revisa el nombre del componente
import  iconImagenUpload  from '../../assets/images/icon-imagen-upload.png'


import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardGroup,
  CCardHeader,
  CCardImage,
  CCardLink,
  CCardSubtitle,
  CCardText,
  CCardTitle,
  CListGroup,
  CListGroupItem,
  CCol,
  CRow,
  CForm,
  CFormLabel,
  CFormInput,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CFormTextarea,
  CCollapse,
  CTable,
  CTableRow,
  CTableHeaderCell,
  // DocsExample,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

import {
  cilCompress,
  cilCloudDownload,
  cilCursor,
  cilChevronBottom,
  cilChevronTop,
  cilCheck,
  cilMedicalCross,
  cilArrowLeft,
  cilArrowTop,
  cilMagnifyingGlass,
} from '@coreui/icons'

import  trash  from '../../assets/images/trash.png'
import  movie  from '../../assets/images/movie.png'

async function senData(credentials) {
  //   return fetch(process.env.REACT_APP_URL_API+'/api/auth/login', {
        return fetch('https://acyapy.deepgaze.xyz/api/video/send-data', {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
  }

function FormInsert({ profile, setProfile }) {
  // const [profile, setProfile] = useState(localStorage.getItem('name') || '');
  const [videoFile, setVideoFile] = useState(null);
  const [kminicio, setKminicio] = useState(null);
  const [kmfin, setKmfin] = useState(null);
  const [tipoCamara, setTipoCamara] = useState(null);
  const [segmento, setSegmento] = useState(null);
  const [visible, setVisible] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null); // Referencia al input de archivo
  const fileNameRef = useRef(null); // Referencia al elemento donde mostrar el nombre
  const referenciaEntradaArchivo = useRef(null);
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navega a la página anterior
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedImage(e.target.files[0]);
      if (fileNameRef.current) { // Actualiza el textContent del elemento correcto
        fileNameRef.current.textContent = selectedImage.name; 
      }
    }
  };

  // const handleFileChange = (event) => {
  //   setFile(event.target.files[0]);
  //   if (fileNameRef.current) {
  //     fileNameRef.current.textContent = file.name; 
  //   }
  // };
  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedImage(file);
  //   // Mostrar el nombre del archivo en el span
  //   const fileNameElement = document.getElementById('fileName');
  //   fileNameElement.textContent = e.target.files[0].name;
  // };

  const handleSelectImage = () => {
    fileInputRef.current.click(); // Simula un click en el input de archivo
  };

  // const handleRemoveImage = () => {
  //   setSelectedImage(null);
  //   referenciaEntradaArchivo.current.value = ''; // Limpia el valor de la entrada de archivo
  //   if (fileNameRef.current) {
  //     fileNameRef.current.textContent = ''; // Limpia el nombre del archivo
  //   }
  // };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    if (referenciaEntradaArchivo.current) { // Verifica que la referencia exista
      referenciaEntradaArchivo.current.value = ''; // Limpia el valor de la entrada de archivo
    }
    if (fileNameRef.current) {
      fileNameRef.current.textContent = ''; // Limpia el nombre del archivo
    }
  };

//   const handleSubmit = async e => {
//     console.log('Error al enviar los datos:');
//     console.log(longitud);
//     e.preventDefault();
    
//     console.log('datos:');
//     const data = await senData({
//       longitud,
//       nsegmento,
//       anchocarril,
//       ncarriles,
//       tipopavimento,
//       estadopavimento
//     });

//     if(data.error){

//         // setError(data.error);
//         // setShow(true);
//         return false;
//     }

    
//     // handleLogin(data);
//     // setToken(data);
//     // setProfile(data.name);
//     // navigate('/dashboard'); // Redirige a '/home'
  
// }
useEffect(() => {
  // const handleScroll = () => {
  //   setShowFooter(window.scrollY > 100); // Ajusta el valor de desplazamiento para mostrar el pie de página
  // };

  // window.addEventListener('scroll', handleScroll);
  // return () => window.removeEventListener('scroll', handleScroll);
  if (fileNameRef.current && selectedImage) {
    fileNameRef.current.textContent = selectedImage.name;
  }
}, [selectedImage]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedImage) return;

    const formData = new FormData();
    formData.append('video', selectedImage); // Nombre del campo 'video'
    formData.append('kminicio', kminicio);
    formData.append('kmfin', kmfin);
    formData.append('tipoCamara', tipoCamara);
    formData.append('segmento', segmento);

    try {
      const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
      // console.log(storedName)
      const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
      // console.log(token)
      const response = await axios.post('https://acyapy.deepgaze.xyz/api/video/send-data', formData, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + token
        }
      });

      console.log('Respuesta del servidor:', response.data);
    } catch (error) {
      console.error('Error al subir el video:', error);
    }
  };

  return (
    <>
    <CRow className='margin-row-contenedor'>
      <CCol xs>
        <CCol xs='12' className='d-flex align-items-center padding-titulo-list-contenedor'>
        <CIcon icon={cilArrowLeft} size="xxl" className="height-auto icon-background-orange" style={{'--ci-primary-color': 'white'}}/><h5 className='margin-bottom-h5'><strong>Nuevo Proyecto</strong></h5>
        </CCol>
      <CCol xs={10} style={{'height': '29rem'}}>
    <CCard className="mb-4 scroll-contenedor-new-proyect" style={{'height': '500px'}}>
      <CCol xs={10} className='titulo-formulario-new-video'>Proyecto ID #</CCol>
    <CCardBody>
     
     {/* <DocsExample href="forms/form-control"> */}
     <CForm onSubmit={handleSubmit}>
     {/* <div>
  <form id="inventarioVialForm" action="/submit-inventario" method="POST"> */}
    <div className="mb-3">
    <CRow>
    <CRow>
            <CCol xs={12}>
            <CFormLabel className='letra-titulo-1' htmlFor="anchoCarril">Carga de Videos</CFormLabel>
            </CCol>
            </CRow>
    <CCol xs={10}>
    <CFormLabel className='letra-contenido-2' htmlFor="Video">Video</CFormLabel>
    <             CCol xs='12' className='quitar-padding'>
                    <CCard className='container-upload-video'>
                  <div className="image-uploader-form-video">
                    <CFormInput  
                    type="file" 
                    accept="video/mp4,video/webm,video/ogg,audio/mpeg,audio/wav" 
                    onChange={handleFileChange} 
                    className="file-input" 
                    ref={fileInputRef}
                    />
                    <CButton className="btn-form-video" onClick={handleSelectImage} ><img src={iconImagenUpload} alt="Selected Image" className="avatar2-video" /></CButton>
                    <span className='letra-contenido'>Arrastra aquí tus videos para subir</span>
                    <span className='letra-contenido'>Formatos MP3, AVI, MOV</span>
                  </div>
                  </CCard>
                  </CCol>
    {/* <CFormInput size="sm" id="Video" placeholder="Ingrese nombre" value={longitud} onChange={(e) => setLongitud(e.target.value)}/> */}
      {/* <label for="tramo">Tramo de Vía</label>
      <input type="text" id="tramo" name="tramo" required/> */}
      </CCol>
      {/* <CCol xs={6}>
      <CFormLabel htmlFor="nsegmento">Número de Segmento</CFormLabel>
      <CFormInput type="number"  id="nsegmento" placeholder="" step="1" min="0" max="5" value={nsegmento} onChange={(e) => setNsegmento(e.target.value)}/>
      </CCol> */}
      </CRow>
    </div>
    
    <div className="mb-3 letra-contenido-2">
      <CRow>
      <CCol xs={5}>
      <CFormLabel htmlFor="kminicio">KM Inicio</CFormLabel>
      <CFormInput className='letra-contenido-modal-input' size="sm"  id="kminicio" placeholder="Ingrese kilometros" value={kminicio} onChange={(e) => setKminicio(e.target.value)}/>
      </CCol>
    
      <CCol xs={5}>
      <CFormLabel htmlFor="kmfin">KM Fin</CFormLabel>
      <CFormInput className='letra-contenido-modal-input' size="sm"  id="kmfin" placeholder="Ingrese kilometros" value={kmfin} onChange={(e) => setKmfin(e.target.value)}/>
      </CCol>
      </CRow>
    </div>

    <div className="mb-3 letra-contenido-2">
    <CRow>
    <CCol xs={5}>
      <CFormSelect size="sm"
          className='letra-contenido-modal-input'
          label="Tipo de Cámara"
          onChange={(e) => setTipoCamara(e.target.value)}>
        <option className='fuente-letra-oscura' >Seleccione tipo</option>
        <option className='fuente-letra-oscura' value="frontal">Frontal</option>
        <option className='fuente-letra-oscura' value="cenital">Cenital</option>
      </CFormSelect>
      </CCol>
      <CCol xs={5}>
      <CFormSelect size="sm"
        className='letra-contenido-modal-input'
        label="Segmento"
        onChange={(e) => setSegmento(e.target.value)}>
          <option className='fuente-letra-oscura' >Seleccione Segmento</option>
          <option className='fuente-letra-oscura' value="1">Segmento1</option>
          <option className='fuente-letra-oscura' value="2">Segmento2</option>
        </CFormSelect>
      </CCol>
      </CRow>
    </div>

    <div className="mb-3 letra-contenido-2">
    <CRow>
    <CCol xs={10}>
    <CTable bordered small className='table-spam-movie'>
    <CTableRow>
    {selectedImage && (
      <CTableHeaderCell id="fileName" className='letra-contenido' scope="col" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <div style={{ display: 'flex', alignItems: 'center' }}> {/* Contenedor para los dos primeros elementos */}
        <CButton className='btn-movie-nf' size="sm">
        <img src={movie} alt="Descripción de la imagen" className="nav-icon width-img-search"/>
        </CButton>
        <span className='spam-file-movie' ref={fileNameRef}></span>
        </div>
        <CButton variant="outline-danger" size="sm" onClick={handleRemoveImage}>
        <img src={trash} alt="Descripción de la imagen" className="nav-icon width-img-search"/>
        </CButton>
      </CTableHeaderCell>
    )}
    </CTableRow>
  {/* </CTableHead> */}
    </CTable>
      </CCol>
      </CRow>
    </div>

    <div className="mb-3">
    <CRow className='delete-border-color'>
      
      <CCol xs={10}>
    <CCard className="mt-3 delete-border-color" >

    <CRow>

      <div className="mb-3 letra-btn-agregar">
            <CRow>
            <CCol xs={12}>
            <CIcon className='icon-add-item' icon={cilMedicalCross} style={{'--ci-primary-color': 'white'}} /> Agregar Video
              </CCol>
              
              </CRow>
            </div>
      
      </CRow>
      </CCard>
      </CCol>
    </CRow>
    </div>
  
    </CForm>
    </CCardBody>
    </CCard>
    
    </CCol>
    </CCol>
    </CRow>
    {profile === 'true' ? (
        <footer className='footer-form-video-estilo'>
        <CRow>
        {/* <CCol xs='2'></CCol>  */}
        <CCol xs='12'>
        <CRow>
          <CCol xs='8' className='footer-text-save-leave letra-contenido-2'><CButton onClick={handleSubmit} className="px-8 btn-guardar-footer" type="submit"><CIcon className='icon-save-leave' icon={cilCheck} style={{'--ci-primary-color': 'white'}}/>Guardar y salir</CButton></CCol>
          <CCol xs='1'>
            <div className="mb-3">
            <CButton onClick={handleGoBack} className="px-8 btn-salir-footer" type="submit">Cancelar</CButton>
      </div>
      </CCol>
          <CCol xs='1' className='padding-left-footer-next'>
            <div className="mb-3">
            <CButton onClick={handleSubmit} className="px-8 btn-next-footer" type="submit">Guardar</CButton>
        
      </div>
      </CCol>
          </CRow>
          </CCol>
        </CRow>
      </footer>
      ) : (
        <footer className='footer-form-video-estilo'>
      <CRow>
      <CCol xs='2'></CCol> 
      <CCol xs='10'>
      <CRow>
        <CCol xs='8' className='footer-text-save-leave letra-contenido-2'><CButton onClick={handleSubmit} className="px-8 btn-guardar-footer" type="submit"><CIcon className='icon-save-leave' icon={cilCheck} style={{'--ci-primary-color': 'white'}}/>Guardar y salir</CButton></CCol>
        <CCol xs='1'>
          <div className="mb-3">
          <CButton onClick={handleGoBack} className="px-8 btn-salir-footer" type="submit">Cancelar</CButton>
    </div>
    </CCol>
        <CCol xs='1' className='padding-left-footer-next'>
          <div className="mb-3">
      <CButton onClick={handleSubmit} className="px-8 btn-next-footer" type="submit">Guardar</CButton>
      
    </div>
    </CCol>
        </CRow>
        </CCol>
      </CRow>
    </footer>
      )}
    </>
  );
}


FormInsert.propTypes = {
  setProfile: PropTypes.func.isRequired

}

export default FormInsert;