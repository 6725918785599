import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilCode, cilMediaPlay } from '@coreui/icons'

import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardGroup,
  CCardHeader,
  CCardImage,
  CCardLink,
  CCardSubtitle,
  CCardText,
  CCardTitle,
  CListGroup,
  CListGroupItem,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableRow,
  CTableDataCell,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from '@coreui/react'

import {
  cilCompress,
  cilCloudDownload,
  cilCenterFocus,
} from '@coreui/icons'

const DocsExample = (props) => {
  const { children, href, tabContentClassName, data } = props
  const [visible, setVisible] = useState(false)


  // Función para obtener datos del servidor
  function truncateText(text, maxLength, truncationText = "...") {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + truncationText;
    }
    return text;
  }

  // Función para descargar la imagen
  const handleDownload = (imageUrl) => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'imagen_descargada.jpg'; // Puedes cambiar el nombre del archivo aquí
    link.click();
  };

  return (
    <>
      <div className="example">
        <CTabContent className={`rounded-bottom ${tabContentClassName ? tabContentClassName : ''}`}>
          <CTabPane className="preview" visible>
            <CCard style={{ width: '100%', height: '18.8rem' }}>
              <div style={{ position: 'relative' }}>
                <CCardImage orientation="top" src={data && data.length > 0 && data[0].url_frame ? data[0].url_frame : "http://localhost:4009/images/casita.jpg"} width="800" height="150" />
                <button className='button-modal-frame' style={{ position: 'absolute', bottom: '10px', right: '40px' }}>
                  <CIcon icon={cilCloudDownload} title="Download file" style={{ '--ci-primary-color': 'white' }} 
                  onClick={() => handleDownload(data && data.length > 0 && data[0].url_frame ? data[0].url_frame : "http://localhost:4009/images/casita.jpg")}/>
                </button>
                <button className='button-modal-frame' style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                  <CIcon icon={cilCompress} title="Download file" style={{ '--ci-primary-color': 'white' }} onClick={() => setVisible(!visible)} />
                </button>
              </div>

              <CCardBody>
                <CCardText className='text-card-frame' width="800" height="140">
                  <CRow>
                    <CCol xs='12'>
                      <CTable borderless className='margin-bottom-table'>
                        <CTableBody>
                          <CTableRow>
                            <CTableDataCell className="quitar-padding letra-titulo-2">
                              <strong>Video:</strong>
                            </CTableDataCell>
                          </CTableRow>
                          <CTableRow>
                            <CTableDataCell className="quitar-padding letra-contenido">
                              {data && data.length > 0 && data[0].name_video ?
                                <div
                                  className="truncate-text"
                                  title={truncateText(data[0].name_video, 30) === data[0].name_video ? '' : data[0].name_video}
                                >
                                  {/* Si se trunca, muestra el tooltip con el nombre completo */}
                                  {truncateText(data[0].name_video, 30)}
                                </div>
                                : "Video_segmento1.avi"}
                            </CTableDataCell>
                          </CTableRow>
                        </CTableBody>
                      </CTable>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol xs='12'>
                      <CTable borderless className='margin-bottom-table'>
                        <CTableBody>
                          <CTableRow>
                            <CTableDataCell className="quitar-padding-with letra-titulo-2">
                              <strong>Tipo de cámara:</strong>
                            </CTableDataCell>
                            <CTableDataCell className="quitar-padding letra-titulo-2">
                              <strong>Frame:</strong>
                            </CTableDataCell>
                          </CTableRow>
                          <CTableRow>
                            <CTableDataCell className="quitar-padding letra-contenido">
                              {data && data.length > 0 && data[0].tipo_camara ? data[0].tipo_camara : "Frontal"}
                            </CTableDataCell>
                            <CTableDataCell className="quitar-padding letra-contenido">
                              {data && data.length > 0 && data[0].id_frame ? data[0].id_frame : "03:35"}
                            </CTableDataCell>
                          </CTableRow>
                        </CTableBody>
                      </CTable>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol xs='12'>
                      <CTable borderless className='margin-bottom-table'>
                        <CTableBody>
                          <CTableRow>
                            <CTableDataCell className="quitar-padding letra-titulo-2">
                              <strong>Tipo de detección:</strong>
                            </CTableDataCell>
                          </CTableRow>
                          <CTableRow>
                            <CTableDataCell className="quitar-padding letra-contenido">
                              {data && data.length > 0 && data[0].tipo ? data[0].tipo : "Señaletica de tránsito"}
                            </CTableDataCell>
                          </CTableRow>
                        </CTableBody>
                      </CTable>
                    </CCol>
                  </CRow>
                </CCardText>
              </CCardBody>
            </CCard>
          </CTabPane>
        </CTabContent>
      </div>
      <CModal
        className=''
        alignment="center"
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="VerticallyCenteredExample"
        size="lg"
      >
        <CModalBody className=''>
          <CCard style={{ width: '48rem', height: '28.5rem' }}>
            <div style={{ position: 'relative' }}>
              {/* <CCardImage orientation="top" src="https://cdn.pixabay.com/photo/2015/07/27/19/10/road-863126_960_720.jpg" width="1000" height="455" /> */}
              <CCardImage orientation="top" src={data && data.length > 0 && data[0].url_frame ? data[0].url_frame : "http://localhost:4009/images/casita.jpg"} width="1000" height="455" />
              <button className='button-modal-frame' style={{ position: 'absolute', bottom: '10px', right: '40px' }}>
                <CIcon icon={cilCloudDownload} title="Download file" style={{ '--ci-primary-color': 'white' }} 
                onClick={() => handleDownload(data && data.length > 0 && data[0].url_frame ? data[0].url_frame : "http://localhost:4009/images/casita.jpg")} />
              </button>
              <button className='button-modal-frame' style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
                <CIcon icon={cilCompress} title="Download file" style={{ '--ci-primary-color': 'white' }} onClick={() => setVisible(!visible)} />
              </button>
            </div>

          </CCard>
        </CModalBody>

      </CModal>
    </>
  )
}

DocsExample.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  tabContentClassName: PropTypes.string,
  data: PropTypes.array // Agrega esta línea para definir la prop data
}

export default React.memo(DocsExample)
