import PropTypes from 'prop-types'
import React, {useState} from 'react'
import { CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilCode, cilMediaPlay } from '@coreui/icons'

import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardGroup,
  CCardHeader,
  CCardImage,
  CCardLink,
  CCardSubtitle,
  CCardText,
  CCardTitle,
  CListGroup,
  CListGroupItem,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableRow,
  CTableDataCell,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from '@coreui/react'

import {
  cilCompress,
  cilCloudDownload,
  cilCenterFocus,
} from '@coreui/icons'

const DocsExample = (props) => {
  const { children, href, tabContentClassName } = props
  const [visible, setVisible] = useState(false)

  return (
    <>
    <div className="example">
  <CTabContent className={`rounded-bottom ${tabContentClassName ? tabContentClassName : ''}`}>
    <CTabPane className="preview" visible>
      <CCard style={{ width: '100%', height: '18.8rem'}}>
        <div style={{ position: 'relative' }}>
          <CCardImage orientation="top" src="https://cdn.pixabay.com/photo/2015/07/27/19/10/road-863126_960_720.jpg" width="800" height="150" />
          <button className='button-modal-frame' style={{ position: 'absolute', bottom: '10px', right: '40px' }}>
          <CIcon icon={cilCloudDownload} title="Download file" style={{'--ci-primary-color': 'white'}} />
          </button>
          <button className='button-modal-frame' style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
          <CIcon icon={cilCompress} title="Download file" style={{'--ci-primary-color': 'white'}} onClick={() => setVisible(!visible)}/>
          </button>
        </div>

        <CCardBody>
          <CCardText className='text-card-frame' width="800" height="140">
            <CRow>
              <CCol xs='12'>
                <CTable borderless className='margin-bottom-table'>
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell className="quitar-padding letra-titulo-2">
                        <strong>Video:</strong>
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="quitar-padding letra-contenido">
                        Video_segmento1.avi
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>     
              </CCol>
            </CRow>
            <CRow>
              <CCol xs='12'>
                <CTable borderless className='margin-bottom-table'>
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell className="quitar-padding-with letra-titulo-2">
                        <strong>Tipo de cámara:</strong>
                      </CTableDataCell>
                      <CTableDataCell className="quitar-padding letra-titulo-2">
                        <strong>Frame:</strong>
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="quitar-padding letra-contenido">
                        Frontal
                      </CTableDataCell>
                      <CTableDataCell className="quitar-padding letra-contenido">
                        03:35
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>  
              </CCol>
            </CRow>
            <CRow>
              <CCol xs='12'>
                <CTable borderless className='margin-bottom-table'>
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell className="quitar-padding letra-titulo-2">
                        <strong>Tipo de detección:</strong>
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                      <CTableDataCell className="quitar-padding letra-contenido">
                        Señaletica de tránsito
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable> 
              </CCol>
            </CRow>
          </CCardText>
        </CCardBody>
      </CCard>
    </CTabPane>
  </CTabContent>
</div>
<CModal
      className=''
      alignment="center"
      visible={visible}
      onClose={() => setVisible(false)}
      aria-labelledby="VerticallyCenteredExample"
      size="lg"
    >
      <CModalBody className=''>
      <CCard style={{ width: '48rem', height: '28.5rem'}}>
        <div style={{ position: 'relative' }}>
          <CCardImage orientation="top" src="https://cdn.pixabay.com/photo/2015/07/27/19/10/road-863126_960_720.jpg" width="1000" height="455" />
          <button className='button-modal-frame' style={{ position: 'absolute', bottom: '10px', right: '40px' }}>
          <CIcon icon={cilCloudDownload} title="Download file" style={{'--ci-primary-color': 'white'}} />
          </button>
          <button className='button-modal-frame' style={{ position: 'absolute', bottom: '10px', right: '10px' }}>
          <CIcon icon={cilCompress} title="Download file" style={{'--ci-primary-color': 'white'}} onClick={() => setVisible(!visible)}/>
          </button>
        </div>

        </CCard>
      </CModalBody>
      
    </CModal>
    </>
  )
}

DocsExample.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  tabContentClassName: PropTypes.string,
}

export default React.memo(DocsExample)
