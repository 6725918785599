import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import '../../scss/style.scss'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css"; // Importar estilos

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CForm,
  CFormLabel,
  CFormInput,
  CFormSelect,
  CFormTextarea,
  CCollapse,

} from '@coreui/react'
import CIcon from '@coreui/icons-react'

import {
  cilChevronBottom,
  cilChevronTop,
  cilCheck,
  cilMedicalCross,
  cilArrowLeft,
  cilCalendar,
} from '@coreui/icons'


function CustomInput({ value, onChange, onFocus }) {
  const inputRef = useRef(null);

  return (
    <div className="input-group">
      <CFormInput
        className="letra-contenido-modal-input"
        size="sm"
        placeholder="Seleccione fecha"
        value={value}
        readOnly
        ref={inputRef}
        //onClick={() => onFocus(inputRef.current)} // Abre el DatePicker al hacer clic en el input
      />
      <div className="input-group-append">
        <span className="input-group-text" onClick={() => onFocus(inputRef.current)}>
          <CIcon
            icon={cilCalendar}
            size="lg"
            className="height-auto"
            style={{ "--ci-primary-color": "black" }}
          />
        </span>
      </div>
    </div>
  );
}

function FormVideo({ profile, setProfile }) {
  const [selectedDate, setSelectedDate] = useState(null); // Inicializar con la fecha 
  const [selectedDate2, setSelectedDate2] = useState(null); // Inicializar con la fecha2
  const [idProyecto, setidProyecto] = useState(null);
  const [idCarretera, setidCarretera] = useState(null);
  const [idSegmento, setidSegmento] = useState(null);
  const [nombreProyecto1, setNombreProyecto1] = useState(null);
  const [fechaInicio1, setFechaInicio1] = useState(null);
  const [fechaFin1, setFechaFin1] = useState(null);
  const [cliente1, setCliente1] = useState(null);
  const [operador1, setOperador1] = useState(null);
  const [descripcion1, setDescripcion1] = useState(null);
  const [nombreCarretera1, setNombreCarretera1] = useState(null);
  const [tipoCarretera, setTipoCarretera] = useState(null);
  const [cantCarriles, setCantCarriles] = useState(null);
  const [concesion, setConcesion] = useState(null);
  const [kmInicio1, setKmInicio1] = useState(null);
  const [kmFin1, setKmFin1] = useState(null);
  const [longitudKm1, setLongitudKm1] = useState(null);
  const [fechaRelevamiento, setFechaRelevamiento] = useState(null);
  const [tipoBanquina, setTipoBanquina] = useState(null);
  const [estadoBanquina, setEstadoBanquina] = useState(null);
  const [anchoInterno, setAnchoInterno] = useState(null);
  const [anchoExterno, setAnchoExterno] = useState(null);
  const [numCarriles, setNumCarriles] = useState(null);
  const [sentidoCarril, setSentidoCarril] = useState(null);
  const [tipoSeparador, setTipoSeparador] = useState(null);
  const [estadoDrenaje, setEstadoDrenaje] = useState(null);
  const [anchoCalzada, setAnchoCalzada] = useState(null);
  const [estadoBordeCalzada, setEstadoBordeCalzada] = useState(null);
  
  const [tipoPavimento, setTipoPavimento] = useState(null);
  const [visible, setVisible] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const datePickerRef = useRef(null); // Referencia para el DatePicker
  const [registro, setRegistro] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dataProyecto, setDataProyecto] = useState(null);

  const handleFocus = (element) => {
    if (datePickerRef.current) {
      datePickerRef.current.focus(); // Ajusta el foco para abrir el DatePicker
    }
  };
  const handleFocus2 = (element) => {
    if (datePickerRef.current) {
      datePickerRef.current.focus(); // Ajusta el foco para abrir el DatePicker
    }
  };
    
//     // handleLogin(data);
//     // setToken(data);
//     // setProfile(data.name);
//     // navigate('/dashboard'); // Redirige a '/home'
  
// }
// useEffect(() => {
//   const handleScroll = () => {
//     setShowFooter(window.scrollY > 100); // Ajusta el valor de desplazamiento para mostrar el pie de página
//   };

//   window.addEventListener('scroll', handleScroll);
//   return () => window.removeEventListener('scroll', handleScroll);
// });

// useEffect(() => {
//   const fetchRegistro = async () => {
//     try {
//       // const response = await axios.get(`/api/registros/${id}`);
//       // setRegistro(response.data);
//       console.log('id ', id)
//     } catch (error) {
//       console.error('Error al obtener el registro:', error);
//     }
//   };

//   fetchRegistro();
// }, [id]);

useEffect(() => {
  const fetchData = async () => {
    setIsLoading(true); // Muestra un indicador de carga mientras se realiza la solicitud

    try {
      const tokenData = localStorage.getItem('token'); // Obtén el token desde el localStorage
      const token = tokenData ? JSON.parse(tokenData).token : null; // Parsear el token JSON

      if (!token) {
        throw new Error('Token no disponible en localStorage'); // Manejar el caso en el que no haya token
      }

      // Realiza la solicitud a la API con el token en los headers
      const response = await fetch(`https://acyapy.deepgaze.xyz/api/edit/project/${id}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      // Si la respuesta no es correcta, lanza un error
      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      // Obtén los datos JSON de la respuesta
      const jsonData = await response.json();
      console.log('Datos JSON recibidos:', jsonData); // Imprime los datos recibidos

      // Actualiza el estado de dataProyecto con los datos recibidos
      setDataProyecto(jsonData);

      // Guarda los datos en el localStorage si es necesario
      localStorage.setItem('data-proyecto', JSON.stringify(jsonData));

    } catch (error) {
      console.error('Error durante la solicitud:', error); // Manejo de errores
      setError(error); // Guarda el error en el estado para mostrarlo en la interfaz
    } finally {
      setIsLoading(false); // Detén el indicador de carga
    }
  };

  fetchData(); // Llama a la función fetchData cuando el componente se monte o cambie el id

  // Este useEffect se ejecutará siempre que cambie `id` y asegurará que los datos se recarguen
}, [id]);

// Este useEffect observa el cambio en dataProyecto y actúa en consecuencia
useEffect(() => {
  if (dataProyecto) {
    console.log('dataProyecto actualizado:', dataProyecto); // Verifica que dataProyecto se actualiza correctamente
    
    setidProyecto(dataProyecto.proyecto[0].id)
    setNombreProyecto1(dataProyecto.proyecto[0].nombre)
    setCliente1(dataProyecto.proyecto[0].cliente)
    setOperador1(dataProyecto.proyecto[0].operador)
    setDescripcion1(dataProyecto.proyecto[0].descripcion)
    setSelectedDate(new Date(dataProyecto.proyecto[0].fecha_inicio));
    setSelectedDate2(new Date(dataProyecto.proyecto[0].fecha_fin));
    if (dataProyecto.carretera[0]) {  
      setidCarretera(dataProyecto.carretera[0].id);
      setNombreCarretera1(dataProyecto.carretera[0].nombre);
      setTipoCarretera(dataProyecto.carretera[0].tipo);
      setConcesion(dataProyecto.carretera[0].concesion);
      setCantCarriles(dataProyecto.carretera[0].cant_carriles);
    }
    if (dataProyecto.segmento[0]) {  
      setidSegmento(dataProyecto.segmento[0].id);
      setKmInicio1(dataProyecto.segmento[0].progresiva_inicio);
      setKmFin1(dataProyecto.segmento[0].progresiva_fin);
      setLongitudKm1(dataProyecto.segmento[0].longitud_segmento);
      setTipoPavimento(dataProyecto.segmento[0].tipo_pavimento);
      setFechaRelevamiento(dataProyecto.segmento[0].fecha_relevamiento);
      setTipoBanquina(dataProyecto.segmento[0].tipo_banquina);
      setEstadoBanquina(dataProyecto.segmento[0].estado_banquina);
      setAnchoInterno(dataProyecto.segmento[0].ancho_banquina_interno);
      setAnchoExterno(dataProyecto.segmento[0].ancho_banquina_externo);
      setNumCarriles(dataProyecto.segmento[0].nro_carril);
      setSentidoCarril(dataProyecto.segmento[0].sentido_carril);
      setTipoSeparador(dataProyecto.segmento[0].tipo_separador_calzada);
      setAnchoCalzada(dataProyecto.segmento[0].ancho_calzada);
      setEstadoDrenaje(dataProyecto.segmento[0].estado_drenaje_calzada);
      setEstadoBordeCalzada(dataProyecto.segmento[0].estado_borde_calzada);
    }
    console.log('nompreproyecto1', dataProyecto.proyecto)
    console.log('nompreproyecto1', nombreProyecto1)
  }
}, [dataProyecto]); // Solo se ejecuta cuando `dataProyecto` cambia

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (!videoFile) return;
    const fechaInicio1 = selectedDate.toISOString().slice(0, 19).replace('T', ' ');
    const fechaFin1 = selectedDate2.toISOString().slice(0, 19).replace('T', ' ');

    const formData = new FormData();
    formData.append('idProyecto', idProyecto); // Nombre del campo 'video'
    formData.append('idCarretera', idCarretera);
    formData.append('idSegmento', idSegmento);
    formData.append('nombreProyecto1', nombreProyecto1); // Nombre del campo 'video'
    formData.append('fechaInicio1', fechaInicio1);
    formData.append('fechaFin1', fechaFin1);
    formData.append('cliente1', cliente1);
    formData.append('operador1', operador1);
    formData.append('descripcion1', descripcion1);
    formData.append('nombreCarretera1', nombreCarretera1);
    formData.append('tipoCarretera', tipoCarretera);
    formData.append('cantCarriles', cantCarriles);
    formData.append('concesion', concesion);
    formData.append('kmInicio1', kmInicio1);
    formData.append('kmFin1', kmFin1);
    formData.append('longitudKm1', longitudKm1);
    formData.append('fechaRelevamiento', fechaRelevamiento);
    formData.append('tipoBanquina', tipoBanquina);
    formData.append('estadoBanquina', estadoBanquina);
    formData.append('anchoInterno', anchoInterno);
    formData.append('anchoExterno', anchoExterno);
    formData.append('numCarriles', numCarriles);
    formData.append('sentidoCarril', sentidoCarril);
    formData.append('tipoSeparador', tipoSeparador);
    formData.append('estadoDrenaje', estadoDrenaje);
    formData.append('anchoCalzada', anchoCalzada);
    formData.append('estadoBordeCalzada', estadoBordeCalzada);
    formData.append('tipoPavimento', tipoPavimento);

    try {
      const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
      // console.log(storedName)
      const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
      // console.log(token)
      const response = await axios.post('https://acyapy.deepgaze.xyz/api/update/project', formData, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + token
        }
      });

      console.log('Respuesta del servidor:', response.data);
    } catch (error) {
      console.error('Error al subir el video:', error);
    }
  };


  return (
    <>
    <CRow className='margin-row-contenedor'>
      <CCol xs>
        <CCol xs='12' className='d-flex align-items-center padding-titulo-list-contenedor'>
        <CIcon icon={cilArrowLeft} size="xxl" className="height-auto icon-background-orange" style={{'--ci-primary-color': 'white'}}/><h5 className='margin-bottom-h5'><strong>Editar Proyecto</strong></h5>
        </CCol>
      <CCol xs={10} style={{'height': '29rem'}}>
    <CCard className="mb-4 scroll-contenedor-new-proyect" style={{'height': '500px'}}>
      <CCol xs={10} className='titulo-formulario-new-proyect'>Proyecto ID #</CCol>
    <CCardBody>
     
     {/* <DocsExample href="forms/form-control"> */}
     <CForm onSubmit={handleSubmit}>
     {/* <div>
  <form id="inventarioVialForm" action="/submit-inventario" method="POST"> */}
    <div className="mb-3 letra-contenido-2">
    <CRow>
    <CCol xs={10}>
    <CFormLabel htmlFor="nombreProyecto1">Nombre de proyecto</CFormLabel>
    <CFormInput className='letra-contenido-modal-input' size="sm" id="nombreProyecto1" placeholder="Ingrese nombre" value={nombreProyecto1} onChange={(e) => setNombreProyecto1(e.target.value)}/>
      {/* <label for="tramo">Tramo de Vía</label>
      <input type="text" id="tramo" name="tramo" required/> */}
      </CCol>
      {/* <CCol xs={6}>
      <CFormLabel htmlFor="nsegmento">Número de Segmento</CFormLabel>
      <CFormInput type="number"  id="nsegmento" placeholder="" step="1" min="0" max="5" value={nsegmento} onChange={(e) => setNsegmento(e.target.value)}/>
      </CCol> */}
      </CRow>
    </div>
    
    <div className="mb-3 letra-contenido-2">
    <CRow>
    <CCol xs={5}>
    <div className="datePicker-container"> {/* Contenedor para label y DatePicker */}
        <CFormLabel htmlFor="fecha-inicio">Fecha de ejecución inicio</CFormLabel>
        <DatePicker
          ref={datePickerRef}
          id="fecha-inicio"
          selected={selectedDate}
          onChange={date => setSelectedDate(date)}
          customInput={
            <CustomInput
              value={selectedDate ? selectedDate.toLocaleDateString() : ""}
              onChange={setSelectedDate}
              onFocus={handleFocus}
            />
          }
        />
      </div>
      </CCol>
      <CCol xs={5}>
      <div className="datePicker-container"> {/* Contenedor para label y DatePicker */}
        <CFormLabel htmlFor="fecha-inicio">Fecha de ejecución fin</CFormLabel>
        <DatePicker
          ref={datePickerRef}
          id="fecha-inicio"
          selected={selectedDate2}
          onChange={date => setSelectedDate2(date)}
          customInput={
            <CustomInput
              value={selectedDate2 ? selectedDate2.toLocaleDateString() : ""}
              onChange={setSelectedDate2}
              onFocus={handleFocus2}
            />
          }
        />
      </div>
      </CCol>
      </CRow>
    </div>
    
    <div className="mb-3 letra-contenido-2">
      <CRow>
      <CCol xs={5}>
      <CFormLabel htmlFor="cliente1">Cliente</CFormLabel>
      <CFormInput className='letra-contenido-modal-input' size="sm"  id="cliente1" placeholder="Ingrese" value={cliente1} onChange={(e) => setCliente1(e.target.value)}/>
      </CCol>
    
      <CCol xs={5}>
      <CFormLabel htmlFor="operador1">Operador</CFormLabel>
      <CFormInput className='letra-contenido-modal-input' size="sm"  id="operador1" placeholder="Ingrese" value={operador1} onChange={(e) => setOperador1(e.target.value)}/>
      </CCol>
      </CRow>
    </div>

    <div className="mb-3 letra-contenido-2">
      <CRow>
      <CCol xs={10}>
      <CFormLabel htmlFor="descripcion1">Descripción</CFormLabel>
      <CFormTextarea
      className='letra-contenido-modal-input'
      id="exampleFormControlTextarea1"
      placeholder="Escribe comentarios u observaciones"
      value={descripcion1} 
      onChange={(e) => setDescripcion1(e.target.value)}
      rows={4}
      ></CFormTextarea>
      </CCol>
    
      
      </CRow>
    </div>
    <hr className='width-hr-form-new-proyect'></hr>
    <CRow>
    <CCol xs={12}>
    
    <CCol xs={10} className='titulo-formulario-new-carretera'>Carretera #1</CCol>
     
     </CCol>
     </CRow>
     <div className="mb-3 letra-contenido-2">
    <CRow>
    <CCol xs={10}>
    <CFormLabel htmlFor="nombreCarretera1">Nombre de carretera/ruta</CFormLabel>
    <CFormInput className='letra-contenido-modal-input' size="sm" id="nombreCarretera1" placeholder="Ingrese nombre" value={nombreCarretera1} onChange={(e) => setNombreCarretera1(e.target.value)}/>
      {/* <label for="tramo">Tramo de Vía</label>
      <input type="text" id="tramo" name="tramo" required/> */}
      </CCol>
      </CRow>
    </div>

    <div className="mb-3 letra-contenido-2">
    <CRow>
    <CCol xs={4}>
      <CFormSelect size="sm"
          className='letra-contenido-modal-input'
          style={{ width: '100%' }}
          label="Tipo de carretera"
          value={tipoCarretera} // Vincula el valor del select al estado
          onChange={(e) => setTipoCarretera(e.target.value)}>
      <option className='fuente-letra-oscura' >Seleccione tipo</option>
        <option className='fuente-letra-oscura' value="autopista">Autopista</option>
        <option className='fuente-letra-oscura' value="autovia">Autovia</option>
        <option className='fuente-letra-oscura' value="carretera">Carretera</option>      
      </CFormSelect>
      </CCol>
      <CCol xs={3}>
      <CFormSelect size="sm"
        className='letra-contenido-modal-input'
        label="Cantidad de carriles"
        value={cantCarriles} // Vincula el valor del select al estado
        onChange={(e) => setCantCarriles(e.target.value)}>
      <option className='fuente-letra-oscura' >Seleccione</option>
        <option className='fuente-letra-oscura' value="1">1</option>
        <option className='fuente-letra-oscura' value="2">2</option>
        <option className='fuente-letra-oscura' value="3">3</option>
        <option className='fuente-letra-oscura' value="4">4</option>       
      </CFormSelect>
      </CCol>
      <CCol xs={3}>
      <CFormSelect size="sm"
        className='letra-contenido-modal-input'
        label="Concesión"
        value={concesion} // Vincula el valor del select al estado
        onChange={(e) => setConcesion(e.target.value)}>
      <option className='fuente-letra-oscura' >Seleccione</option>
        <option className='fuente-letra-oscura' value="si">Si</option>
        <option className='fuente-letra-oscura' value="no">No</option>      
      </CFormSelect>
      </CCol>
      </CRow>
    </div>

    <div className="mb-3">
    <CRow className='delete-border-color'>
      
      <CCol xs={10}>
    <CCard className="mt-3 delete-border-color" >
    <CRow className=''>
      
    <CCol xs={9} className='titulo-formulario-new-segmento segmento-form-padding'>Segmento #1</CCol>
    <CCol xs={1}></CCol>
    <CCol xs={1}></CCol>
      <CCol xs={1}>
    <CButton className="btn-collapse-orange" onClick={() => setVisible(!visible)}>
    {visible ? (
        <CIcon icon={cilChevronBottom} style={{'--ci-primary-color': 'white'}} />
      ) : (
        <CIcon icon={cilChevronTop} style={{'--ci-primary-color': 'white'}} />
      )}
    {/* <CIcon icon={cilChevronBottom} style={{'--ci-primary-color': 'white'}} /> */}
    </CButton>
    </CCol>
    </CRow>
    <CRow>
      
    <CCollapse visible={visible}>
      <CCard className="mt-3">
        <CCardBody className='background-segmento-collapse'>
          <div className="mb-3 letra-contenido-2">
          <CRow>
            <CCol xs={12}>
            <CRow>
            <CCol xs={3}>
            <CFormLabel htmlFor="kmInicio1">KM Inicio</CFormLabel>
            <CFormInput className='letra-contenido-modal-input' size="sm"  id="kmInicio1" placeholder="Ingrese" value={kmInicio1} onChange={(e) => setKmInicio1(e.target.value)}/>
            </CCol>
          
            <CCol xs={3}>
            <CFormLabel htmlFor="kmFin1">KM Fin</CFormLabel>
            <CFormInput className='letra-contenido-modal-input' size="sm" id="kmFin1" placeholder="Ingrese" value={kmFin1} onChange={(e) => setKmFin1(e.target.value)}/>
            </CCol>
            
            <CCol xs={6}>
            <CFormLabel htmlFor="longitudKm1">Longitud (en KM)</CFormLabel>
            <CFormInput className='letra-contenido-modal-input' size="sm" id="longitudKm1" placeholder="00" value={longitudKm1} onChange={(e) => setLongitudKm1(e.target.value)}/>
            </CCol>
            </CRow>
            </CCol>
            </CRow>
          </div>

          <div className="mb-3 letra-contenido-2">
            <CRow>
            <CCol xs={6}>
              <CFormSelect size="sm"
                  className='letra-contenido-modal-input'
                  label="Tipo de pavimento"
                  value={tipoPavimento}
                  onChange={(e) => setTipoPavimento(e.target.value)}>
              <option className='fuente-letra-oscura' >Seleccione tipo</option>
              <option className='fuente-letra-oscura' value="asfalto">Asfalto</option>
              <option className='fuente-letra-oscura' value="hormigon">Hormigon</option>
            </CFormSelect>
              </CCol>
              <CCol xs={6}>
              <CFormSelect size="sm"
                className='letra-contenido-modal-input'
                label="Fecha de relevamiento"
                value={fechaRelevamiento}
                onChange={(e) => setFechaRelevamiento(e.target.value)}>
              <option className='fuente-letra-oscura' >Seleccione fecha</option>
              <option className='fuente-letra-oscura' value="asfalto">Asfalto</option>
              <option className='fuente-letra-oscura' value="hormigon">Hormigon</option>
            </CFormSelect>
              </CCol>
              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
          <CRow>
          <CCol xs={12}>
            <CRow>
            <CCol xs={12}>
            <CFormLabel htmlFor="anchoCarril">Banquina</CFormLabel>
            </CCol>
            </CRow>
            <CRow>
                  <CCol xs={6}>
                    <CFormSelect size="sm"
                        className='letra-contenido-modal-input'
                        label="Tipo de banquina"
                        value={tipoBanquina}
                        onChange={(e) => setTipoBanquina(e.target.value)}>
                    <option className='fuente-letra-oscura' >Seleccione tipo</option>
                    <option className='fuente-letra-oscura' value="pavimentada">Pavimentada</option>
                    <option className='fuente-letra-oscura' value="noPavimentada">No Pavimentada</option>
                  </CFormSelect>
                    </CCol>
                    <CCol xs={6}>
                    <CFormSelect size="sm"
                      className='letra-contenido-modal-input'
                      label="Estado"
                      value={estadoBanquina}
                      onChange={(e) => setEstadoBanquina(e.target.value)}>
                    <option className='fuente-letra-oscura' >Seleccione estado</option>
                    <option className='fuente-letra-oscura' value="bueno">Bueno</option>
                    <option className='fuente-letra-oscura' value="regular">Regular</option>
                    <option className='fuente-letra-oscura' value="malo">Malo</option>
                  </CFormSelect>
                    </CCol>
              
            </CRow>
            </CCol>
              
            </CRow>
          </div>

          <div className="mb-3 letra-contenido-2">
          <CRow>
          <CCol xs={12}>
            <CRow>
            <CCol xs={6}>
            <CFormLabel htmlFor="anchoInterno">Ancho interno</CFormLabel>
            <CFormInput className='letra-contenido-modal-input' size="sm" id="anchoInterno" placeholder="Ingrese" value={anchoInterno} onChange={(e) => setAnchoInterno(e.target.value)}/>
            </CCol>
          
            <CCol xs={6}>
            <CFormLabel htmlFor="anchoExterno">Ancho externo</CFormLabel>
            <CFormInput className='letra-contenido-modal-input' size="sm" id="anchoExterno" placeholder="Ingrese" value={anchoExterno} onChange={(e) => setAnchoExterno(e.target.value)}/>
            </CCol>
          
            </CRow>
            </CCol>
            </CRow>
          </div>

          <div className="mb-3 letra-contenido-2">
          <CRow>
          <CCol xs={12}>
            <CRow>
            <CCol xs={12}>
            <CFormLabel htmlFor="anchoCarril">Carril</CFormLabel>
            </CCol>
            </CRow>
            <CRow>
                  <CCol xs={6}>
                    <CFormSelect size="sm"
                        className='letra-contenido-modal-input'
                        label="Número de carriles"
                        value={numCarriles}
                        onChange={(e) => setNumCarriles(e.target.value)}>
                    <option className='fuente-letra-oscura' >Seleccione estado</option>
                    <option className='fuente-letra-oscura' value="1">1</option>
                    <option className='fuente-letra-oscura' value="2">2</option>
                    <option className='fuente-letra-oscura' value="3">3</option>
                    <option className='fuente-letra-oscura' value="4">4</option>
                    <option className='fuente-letra-oscura' value="5">5</option>
                    <option className='fuente-letra-oscura' value="6">6</option>
                    <option className='fuente-letra-oscura' value="7">7</option>
                  </CFormSelect>
                    </CCol>
                    <CCol xs={6}>
                    <CFormSelect size="sm"
                      className='letra-contenido-modal-input'
                      label="Sentido de carril"
                      value={sentidoCarril}
                      onChange={(e) => setSentidoCarril(e.target.value)}>
                    <option className='fuente-letra-oscura' >Seleccione estado</option>
                    <option className='fuente-letra-oscura' value="ascendente">Ascendente</option>
                    <option className='fuente-letra-oscura' value="descendente">Descendente</option>
                  </CFormSelect>
                    </CCol>
              
            </CRow>
            </CCol>
              
            </CRow>
          </div>

          <div className="mb-3 letra-contenido-2">
          <CRow>
          <CCol xs={12}>
            <CRow>
            <CCol xs={12}>
            <CFormLabel htmlFor="anchoCarril">Calzada</CFormLabel>
            </CCol>
            </CRow>
            <CRow>
                  <CCol xs={6}>
                    <CFormSelect size="sm"
                        className='letra-contenido-modal-input'
                        label="Tipo de separador"
                        value={tipoSeparador}
                        onChange={(e) => setTipoSeparador(e.target.value)}>
                    <option className='fuente-letra-oscura' >Seleccione</option>
                    <option className='fuente-letra-oscura' value="newJersey">New Jersey</option>
                    <option className='fuente-letra-oscura' value="barandaMelalica">Baranda Melálica</option>
                    <option className='fuente-letra-oscura' value="canteroCentral">Cantero Central</option>
                  </CFormSelect>
                    </CCol>
                    <CCol xs={6}>
                      <CFormLabel htmlFor="anchoCalzada">Ancho de calzada</CFormLabel>
                      <CFormInput className='letra-contenido-modal-input' size="sm"  id="anchoCalzada" placeholder="Ingrese" value={anchoCalzada} onChange={(e) => setAnchoCalzada(e.target.value)}/>
                      </CCol>
              
            </CRow>
            </CCol>
              
            </CRow>
          </div>

          <div className="mb-3 letra-contenido-2">
            <CRow>
            <CCol xs={6}>
              <CFormSelect size="sm"  
                  className='letra-contenido-modal-input'
                  label="Estado de drenaje"
                  value={estadoDrenaje}
                  onChange={(e) => setEstadoDrenaje(e.target.value)}>
                    <option className='fuente-letra-oscura' >Seleccione estado</option>
                    <option className='fuente-letra-oscura' value="bueno">Bueno</option>
                    <option className='fuente-letra-oscura' value="regular">Regular</option>
                    <option className='fuente-letra-oscura' value="malo">Malo</option>
                  </CFormSelect>
              </CCol>
              <CCol xs={6}>
              <CFormSelect size="sm"
                className='letra-contenido-modal-input'
                label="Estado de borde de calzada"
                value={estadoBordeCalzada}
                onChange={(e) => setEstadoBordeCalzada(e.target.value)}>
                  <option className='fuente-letra-oscura' >Seleccione estado</option>
                  <option className='fuente-letra-oscura' value="bueno">Bueno</option>
                  <option className='fuente-letra-oscura' value="regular">Regular</option>
                  <option className='fuente-letra-oscura' value="malo">Malo</option>
                </CFormSelect>
              </CCol>
              </CRow>
            </div>

        </CCardBody>
      </CCard>
    </CCollapse>
      
      <br></br>
      <hr></hr>

      <div className="mb-3 letra-btn-agregar">
            <CRow>
            <CCol xs={12}>
            <CIcon className='icon-add-item' icon={cilMedicalCross} style={{'--ci-primary-color': 'white'}} /> Agregar Segmento
              </CCol>
              
              </CRow>
            </div>

            <div className="mb-3 letra-btn-agregar">
            <CRow>
            <CCol xs={12}>
            <CIcon className='icon-add-item' icon={cilMedicalCross} style={{'--ci-primary-color': 'white'}} /> Agregar Carretera
              </CCol>
              
              </CRow>
            </div>
      
      </CRow>
      </CCard>
      </CCol>
    </CRow>
    </div>
     
    </CForm>
    </CCardBody>
    </CCard>
    
    </CCol>
    </CCol>
    </CRow>
    
        
    {profile === 'true' ? (
        <footer className='footer-form-video-estilo'>
        <CRow>
        {/* <CCol xs='2'></CCol>  */}
        <CCol xs='12'>
        <CRow>
          <CCol xs='8' className='footer-text-save-leave letra-contenido-2'><CButton onClick={handleSubmit} className="px-8 btn-guardar-footer" type="submit"><CIcon className='icon-save-leave' icon={cilCheck} style={{'--ci-primary-color': 'white'}}/>Guardar y salir</CButton></CCol>
          <CCol xs='1'>
            <div className="mb-3">
        <CButton href='/dashboard' className="px-8 btn-salir-footer" type="submit">Cancelar</CButton>
      </div>
      </CCol>
          <CCol xs='1' className='padding-left-footer-next'>
            <div className="mb-3">
        <CButton className="px-8 btn-next-footer" type="submit">Siguiente</CButton>
        
      </div>
      </CCol>
          </CRow>
          </CCol>
        </CRow>
      </footer>
      ) : (
        <footer className='footer-form-video-estilo'>
      <CRow>
      <CCol xs='2'></CCol> 
      <CCol xs='10'>
      <CRow>
        <CCol xs='8' className='footer-text-save-leave letra-contenido-2'><CButton onClick={handleSubmit} className="px-8 btn-guardar-footer" type="submit"><CIcon className='icon-save-leave' icon={cilCheck} style={{'--ci-primary-color': 'white'}}/>Guardar y salir</CButton></CCol>
        <CCol xs='1'>
          <div className="mb-3">
      <CButton href='/dashboard' className="px-8 btn-salir-footer" type="submit">Cancelar</CButton>
    </div>
    </CCol>
        <CCol xs='1' className='padding-left-footer-next'>
          <div className="mb-3">
      <CButton href='/new-video/form' className="px-8 btn-next-footer" type="submit">Siguiente</CButton>
      
    </div>
    </CCol>
        </CRow>
        </CCol>
      </CRow>
    </footer>
      )}
    </>
  );
}


FormVideo.propTypes = {
  setProfile: PropTypes.func.isRequired

}

export default FormVideo;