import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'; // Importa Link

import {
  CAvatar,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CFormLabel,
  CFormInput,
  CFormSelect,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CModalContent,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilCursor,
  cilOptions,
  cilReload,
} from '@coreui/icons'

import avatar1 from '../../assets/images/avatars/1.jpg'

import WidgetsBrand from '../widgets/WidgetsBrand'
import WidgetsDropdown from '../widgets/WidgetsDropdown'
import  grupo5774  from '../../assets/images/Grupo-5774.png'

const List = ({ profile, setProfile }) => {
  // const [profile, setProfile] = useState(localStorage.getItem('name') || '');
  const [nombreProyecto, setNombreProyecto] = useState(null);
  const [periodo, setPeriodo] = useState(null);
  const [estado, setEstado] = useState(null);
  const [visible, setVisible] = useState(false)
  const [scrollOffset, setScrollOffset] = useState(0);
  const tableRef = useRef(null); // Referencia para la tabla
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [itemId, setItemId] = useState(null); // Estado para almacenar el ID del elemento a eliminar

   // Función para obtener datos del servidor
   function truncateText(text, maxLength, truncationText = "...") {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + truncationText;
    }
    return text;
  }

   async function fetchData(){
    setIsLoading(true);
    try {
      const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
      const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/jwt/list/project`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
      const jsonData = await response.json();
      localStorage.setItem('data', JSON.stringify(jsonData));

    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Llama a fetchData cuando el componente se monta
  useEffect(() => {
    fetchData();
  }, []);

  const deleteProject = async (id) => {
    try {
      const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
      const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/delete/project/${id}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        },
        method: 'DELETE',
      });

      // Maneja la respuesta (por ejemplo, muestra un mensaje de éxito)
      if (response.ok) {
        // ...
      } else {
        // ...
      }
    } catch (error) {
      console.error('Error eliminando proyecto:', error);
    }
  };

  const handleFilter = () => {
    // Restablece los filtros a null
    setNombreProyecto('');
    setEstado(null);
    setPeriodo(null);
    document.getElementById("estadoProyecto").getElementsByTagName('option')[0].selected = 'selected'
    document.getElementById("periodo").getElementsByTagName('option')[0].selected = 'selected'
  };

  const handleDelete = (id) => {
    setItemId(id); // Almacena el ID antes de abrir el modal
    setVisible(true);
  };

  const handleConfirmDelete = () => {
    // Llama a tu función de API con el itemId
    console.log('itemId1: ', itemId)
    deleteProject(itemId); 
    console.log('itemId: ', itemId)

    // Cierra el modal
    setVisible(false);
  };

  return (
    <>
      <CRow className='margin-row-contenedor'>
        <CCol xs>
        <CCol xs='12' className='d-flex align-items-center padding-titulo-list-contenedor'>
        <CIcon icon={cilCursor} size="xxl" className="height-auto icon-background-orange" style={{'--ci-primary-color': 'white'}}/><h5 className='margin-bottom-h5'><strong>Proyectos</strong></h5>
        </CCol>
          <CCard className="mb-4">
            {/* <CCardHeader>Todos los Proyectos</CCardHeader> */}
            <CCardBody className='contenedor-table-list-titulo'>
            <CCard className='container-card-list'>

                <div className="mb-4 letra-contenido-2">
                <CRow className='row-list-field'>
                <CCol xs={3} className='solid-col-list'>
                <CFormLabel htmlFor="nombreProyecto">ID / Nombre de proyecto</CFormLabel>
                <CFormInput className="input-with-icon form-select-list-estado letra-contenido-modal-input" id="nombreProyecto" placeholder="Ingrese nombre o ID" value={nombreProyecto} onChange={(e) => setNombreProyecto(e.target.value)}/>
                </CCol>
                
                <CCol xs={3} className='solid-col-list'>
                <CFormSelect className='form-select-list-estado-2 fuente-letra-gris letra-contenido-modal-input'
                id='estadoProyecto'
                label="Estado de proyecto"
                value={estado} // Establece el valor actual del estado
                onChange={(e) => setEstado(e.target.value)}>
                  <option className='fuente-letra-oscura' >Seleccione estado</option>
                  <option className='fuente-letra-oscura' value="creado">Creado</option>
                  <option className='fuente-letra-oscura' value="procesando">Procesando</option>
                  <option className='fuente-letra-oscura' value="finalizado">Finalzado</option>
                </CFormSelect>
                </CCol>
                <CCol xs={4} className='solid-col-list'>
                <CRow className=''>
                <CCol xs={8} className='solid-col-list-2'>
                <CFormSelect className='form-select-list-estado-3 fuente-letra-gris letra-contenido-modal-input'
                id='periodo'
                label="Periodo"
                value={periodo} // Establece el valor actual del estado
                onChange={(e) => setPeriodo(e.target.value)}>
                  <option className='fuente-letra-oscura' >Seleccione periodo</option>
                  <option className='fuente-letra-oscura' value="7">Últimos 7 dias</option>
                  <option className='fuente-letra-oscura' value="15">Últimos 15 dias</option>
                  <option className='fuente-letra-oscura' value="30">Últimos 30 dias</option>
                  <option className='fuente-letra-oscura' value="90">Últimos 90 dias</option>
                </CFormSelect>
                </CCol>
                <CCol xs={4} className='solid-col-list-3'>
                <CButton className="btn-search-list form-select-list-estado" type="submit" style={{ display: 'flex', alignItems: 'center' }}><CIcon icon={cilReload} style={{'--ci-primary-color': 'black'}} onClick={handleFilter}/></CButton>
                {/* <CButton className="btn-search-list form-select-list-estado" type="submit" style={{ display: 'flex', alignItems: 'center' }}> <img src={grupo5774} alt="Descripción de la imagen" className="nav-icon width-img-search" onClick={handleFilter} /></CButton> */}
                </CCol>
                </CRow>
                </CCol>
                
                
                <CCol xs={2} className='solid-col-list-4'>
                  <CRow className='btn-list'>
                    <CCol xs={12}>
                <CButton href="/new-project/form" className="px-8 btn-primary-orange" type="submit">Nuevo Proyecto</CButton>
                </CCol>
                </CRow>
                </CCol>
                </CRow>
                </div>
            </CCard>
            </CCardBody>
            <CCardBody className='contenedor-table-list container-tabla letra-contenido-2'>
            
            <div className='contenedor-scroll-tabla-list' style={{ overflowy: 'auto', height:'370px' }}>
            {/* <div 
          ref={tableRef} 
          style={{ 
            width: '100', // Ancho del contenido de la tabla
            display: 'flex', 
            justifyContent: 'flex-start',
            scrollLeft: scrollOffset // Desplazamiento controlado por JavaScript
          }}
        > */}
              <CTable striped small align="middle" className="mb-0 ctable-list" style={{ width: '100%' }}>
                <CTableHead className='letra-contenido-2 sticky-header'>
                  <CTableRow>
                    <CTableHeaderCell className='letra-contenido-2 text-center'  style={{ width: '11vh' }}>
                      ID
                    </CTableHeaderCell>
                    <CTableHeaderCell className='letra-contenido-2 fgh' >Nombre</CTableHeaderCell>
                    <CTableHeaderCell className='letra-contenido-2 fgh' >Fecha creacíon</CTableHeaderCell>
                    <CTableHeaderCell className='letra-contenido-2 fgh' >Estado</CTableHeaderCell>
                    <CTableHeaderCell className='letra-contenido-2 fgh' >Fecha inicio</CTableHeaderCell>
                    <CTableHeaderCell className='letra-contenido-2 fgh' >Fecha fin</CTableHeaderCell>
                    <CTableHeaderCell className='letra-contenido-2 fgh' >Cliente</CTableHeaderCell>
                    <CTableHeaderCell className='letra-contenido-2 fgh' >Operador</CTableHeaderCell>
                    <CTableHeaderCell className='letra-contenido-2' >Descripcíon</CTableHeaderCell>
                    <CTableHeaderCell ></CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody className='letra-contenido-3'>
                {isLoading ? (
                <div>Cargando datos...</div> // Muestra un indicador de carga
              ) : (
                // Renderiza la tabla con los datos de localStorage
                localStorage.getItem('data') && ( 
                  JSON.parse(localStorage.getItem('data'))
                    .filter((item) => {
                      // Calcula la fecha límite en base al periodo
                      const currentDate = new Date();
                      const limitDate = new Date(currentDate.getTime() - periodo * 24 * 60 * 60 * 1000);
                      const fechaInicio = new Date(item.fecha_inicio);
                      return (
                        (nombreProyecto === null || item.nombre.toLowerCase().includes(nombreProyecto.toLowerCase())) && 
                        (estado === null || item.estado === estado) &&
                        (periodo === null || fechaInicio >= limitDate) // Compara con fecha_inicio
                      );
                    })
                    .map((item, index) => (
                    <CTableRow v-for="item in tableItems" key={index}>
                      <CTableDataCell className="letra-contenido-3 my-tall-cell text-center">
                      {item.id}
                      </CTableDataCell>
                      <CTableDataCell className='letra-contenido-3 my-tall-cell truncate-cell'>
                      <div 
                        className="truncate-text" 
                        title={truncateText(item.nombre, 20) === item.nombre ? '' : item.nombre} 
                      > 
                        {/* Si se trunca, muestra el tooltip con el nombre completo */}
                        {truncateText(item.nombre, 20)} 
                      </div>
                      </CTableDataCell>
                      <CTableDataCell className="letra-contenido-3 my-tall-cell">
                      <div>{item.fecha_creacion}</div>
                        {/* <CIcon size="xl" icon={item.country.flag} title={item.country.name} /> */}
                      </CTableDataCell>
                      <CTableDataCell className='letra-contenido-3 my-tall-cell'>
                        <div className="d-flex justify-content-between text-nowrap">
                        <div>{item.estado}</div>
                          <div className="ms-3">
                            {/* <small className="text-body-secondary">{item.usage.period}</small> */}
                          </div>
                        </div>
                        {/* <CProgress thin color={item.usage.color} value={item.usage.value} /> */}
                      </CTableDataCell>
                      <CTableDataCell className="letra-contenido-3 my-tall-cell">
                      <div>{item.fecha_inicio}</div>
                      </CTableDataCell>
                      <CTableDataCell className="letra-contenido-3 my-tall-cell">
                      <div>{item.fecha_fin}</div>
                        {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                      </CTableDataCell>
                      <CTableDataCell className="letra-contenido-3 my-tall-cell">
                      <div 
                        className="truncate-text" 
                        title={truncateText(item.cliente, 10) === item.cliente ? '' : item.cliente} 
                      > 
                        {/* Si se trunca, muestra el tooltip con el nombre completo */}
                        {truncateText(item.cliente, 10)} 
                      </div>
                        {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                      </CTableDataCell>
                      <CTableDataCell className="letra-contenido-3 my-tall-cell">
                      <div 
                        className="truncate-text" 
                        title={truncateText(item.operador, 10) === item.operador ? '' : item.operador} 
                      > 
                        {/* Si se trunca, muestra el tooltip con el nombre completo */}
                        {truncateText(item.operador, 10)} 
                      </div>
                        {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                      </CTableDataCell>
                      <CTableDataCell className="letra-contenido-3 my-tall-cell">
                      <div 
                        className="truncate-text" 
                        title={truncateText(item.descripcion, 20) === item.descripcion ? '' : item.descripcion} 
                      > 
                        {/* Si se trunca, muestra el tooltip con el nombre completo */}
                        {truncateText(item.descripcion, 20)} 
                      </div>
                        {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                      </CTableDataCell>
                      <CTableDataCell>
                      <CDropdown variant="btn-group">
                        <CDropdownToggle className='dropdown-toggle-list btn-dropdown-list' color="white"><CIcon icon={cilOptions} style={{'--ci-primary-color': 'black'}}/></CDropdownToggle>
                        <CDropdownMenu className='dropdown-menu-list'>
                          <CDropdownItem href={`/detail-project/${item.id}`}>Ver detalle</CDropdownItem>
                          <CDropdownItem href={`/project/form-edit/${item.id}`}>Editar</CDropdownItem>
                          <CDropdownItem className="cursor-pointer" onClick={() => handleDelete(item.id)}>Eliminar</CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                      </CTableDataCell>
                    </CTableRow>
                  ))
                
                )
                  )}
                </CTableBody>
              </CTable>
              </div>
              {/* </div> */}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      <CModal
        alignment="center"
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="VerticallyCenteredExample"
        size='sm'
      >
        <CModalContent style={{'width': '400px'}}>
        <CModalHeader style={{'--cui-modal-header-border-color': 'white'}}>
          <CModalTitle className='letra-titulo-modal' id="VerticallyCenteredExample">Eliminar Proyecto</CModalTitle>
        </CModalHeader>
        <CModalBody className='letra-contenido-modal'>
          Estas por eliminar el proyecto de manera definitiva, recuerda que una vez eliminado no podras recuperar esa información.
          ¿Estas seguro que deses eliminarlo?
        </CModalBody>
        <CModalFooter style={{'--cui-modal-footer-border-color': 'white'}}>
          <CButton className='btn-salir-footer' onClick={() => setVisible(false)}>
            Cancelar
          </CButton>
          <CButton className='btn-next-footer' onClick={handleConfirmDelete}>Aceptar</CButton>
        </CModalFooter>
        </CModalContent>
      </CModal>
    </>
  )
}

export default List
