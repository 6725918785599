import React, { useState, useEffect, useRef } from 'react';
import { CIconSvg, CIcon } from '@coreui/icons-react';
import * as icons from '@coreui/icons';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'; 
import { icon } from 'leaflet';
import { useMap } from 'react-leaflet/hooks';
import { useMapEvents } from 'react-leaflet/hooks';
import "leaflet/dist/leaflet.css";
import '../../index.css';
import  marketIcon  from '../../assets/brand/turn-left.png';
import  marketIcon2  from '../../assets/brand/turn-right.png';
import  marketRuta  from '../../assets/brand/ruta.png';
import  marketSeñal  from '../../assets/brand/señal.png';
import  marketMapaSeñal  from '../../assets/brand/mapa-señal.png';
import  marketCartel  from '../../assets/brand/cartel.png';
import Widgets from '../widgets/Widgets';
import { 
  CCard, 
  CButton, 
  CTab, 
  CTabs,
  CTabList,
  CTabContent,
  CTabPanel,
  CFormInput,
  CFormLabel,
  CCol, 
  CRow,
  CCardBody,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormSelect,
  CModalContent,
  CTooltip,
  CFormTextarea,
} from '@coreui/react';

import {
  cibCcAmex,
  cibCcApplePay,
  cibCcMastercard,
  cibCcPaypal,
  cibCcStripe,
  cibCcVisa,
  cifBr,
  cifEs,
  cifFr,
  cifIn,
  cifPl,
  cifUs,
  cilMagnifyingGlass,
  cilMap,
  cilOptions,
  cilCloudDownload,
} from '@coreui/icons'

import avatar1 from '../../assets/images/avatars/1.jpg'
import avatar2 from '../../assets/images/avatars/2.jpg'
import avatar3 from '../../assets/images/avatars/3.jpg'
import avatar4 from '../../assets/images/avatars/4.jpg'
import avatar5 from '../../assets/images/avatars/5.jpg'
import avatar6 from '../../assets/images/avatars/6.jpg'

export default function MyMapComponent({ profile, setProfile }) {
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [senaleticaReclasificada, setSenaleticaReclasificada] = useState(null);
  const [descripcionReclasificada, setDescripcionReclasificada] = useState(null);
  const [descripcion, setDescripcion] = useState(null);
  const [severidadReclasificada, setSeveridadReclasificada] = useState(null);
  const [tipoSenal, setTipoSenal] = useState(null);
  const [senaleticaDetectada, setSenaleticaDetectada] = useState(null);
  const [descripcionDetectada, setDescripcionDetectada] = useState(null);
  const [observacionesSenal, setObservacionesSenal] = useState(null);
  const [latitudSenal, setLatitudSenal] = useState(null);
  const [longitudSenal, setLongitudSenal] = useState(null);
  const [carreteraAsociadaSenal, setCarreteraAsociadaSenal] = useState(null);
  const [fechaDeteccionSenal, setFechaDeteccionSenal] = useState(null);
  const [puntokmSenal, setPuntokmSenal] = useState(null);
  const [sentidoSenal, setSentidoSenal] = useState(null);
  const [tipoDano, setTipoDano] = useState(null);
  const [area, setArea] = useState(null);
  const [severidad, setSeveridad] = useState(null);
  const [observaciones, setObservaciones] = useState(null);
  const [latitudDano, setLatitudDano] = useState(null);
  const [longitudDano, setLongitudDano] = useState(null);
  const [carreteraAsociadaDano, setCarreteraAsociadaDano] = useState(null);
  const [fechaDeteccionDano, setfechaDeteccionDano] = useState(null);
  const [PuntokmDano, setPuntokmDano] = useState(null);
  const [sentidoDano, setsentidoDano] = useState(null);
  const [scrollOffset, setScrollOffset] = useState(0);
  const tableRef = useRef(null); // Referencia para la tabla
  
  // const [center, setCenter] = useState([50.5, 30.5]);
  const tableExample = [
    {
      avatar: { src: avatar1, status: 'success' },
      user: {
        name: 'Yiorgos Avraamu',
        new: true,
        registered: 'Jan 1, 2023',
      },
      country: { name: 'USA', flag: cifUs },
      usage: {
        value: 50,
        period: 'Jun 11, 2023 - Jul 10, 2023',
        color: 'success',
      },
      payment: { name: 'Mastercard', icon: cibCcMastercard },
      activity: '10 sec ago',
    },
    {
      avatar: { src: avatar2, status: 'danger' },
      user: {
        name: 'Avram Tarasios',
        new: false,
        registered: 'Jan 1, 2023',
      },
      country: { name: 'Brazil', flag: cifBr },
      usage: {
        value: 22,
        period: 'Jun 11, 2023 - Jul 10, 2023',
        color: 'info',
      },
      payment: { name: 'Visa', icon: cibCcVisa },
      activity: '5 minutes ago',
    },
    {
      avatar: { src: avatar3, status: 'warning' },
      user: { name: 'Quintin Ed', new: true, registered: 'Jan 1, 2023' },
      country: { name: 'India', flag: cifIn },
      usage: {
        value: 74,
        period: 'Jun 11, 2023 - Jul 10, 2023',
        color: 'warning',
      },
      payment: { name: 'Stripe', icon: cibCcStripe },
      activity: '1 hour ago',
    },
    {
      avatar: { src: avatar4, status: 'secondary' },
      user: { name: 'Enéas Kwadwo', new: true, registered: 'Jan 1, 2023' },
      country: { name: 'France', flag: cifFr },
      usage: {
        value: 98,
        period: 'Jun 11, 2023 - Jul 10, 2023',
        color: 'danger',
      },
      payment: { name: 'PayPal', icon: cibCcPaypal },
      activity: 'Last month',
    },
    {
      avatar: { src: avatar5, status: 'success' },
      user: {
        name: 'Agapetus Tadeáš',
        new: true,
        registered: 'Jan 1, 2023',
      },
      country: { name: 'Spain', flag: cifEs },
      usage: {
        value: 22,
        period: 'Jun 11, 2023 - Jul 10, 2023',
        color: 'primary',
      },
      payment: { name: 'Google Wallet', icon: cibCcApplePay },
      activity: 'Last week',
    },
    {
      avatar: { src: avatar6, status: 'danger' },
      user: {
        name: 'Friderik Dávid',
        new: true,
        registered: 'Jan 1, 2023',
      },
      country: { name: 'Poland', flag: cifPl },
      usage: {
        value: 43,
        period: 'Jun 11, 2023 - Jul 10, 2023',
        color: 'success',
      },
      payment: { name: 'Amex', icon: cibCcAmex },
      activity: 'Last week',
    },

    
  ]

  const [showMarkers, setShowMarkers] = useState(true); // Estado para controlar la visibilidad de los marcadores
  const position = [-34.61315, -58.37723]
  const position2 = [-34.61615, -58.39723]

  const markers = [
    {
    geocode: [-34.61315, -58.37723],
    popup: "Hello"
    }
  ];

  const customIcon = new icon({
    iconUrl: marketIcon,
    iconSize: [38,38] //size icon
    // iconSIze: [1.5,1.5]
  });
  const customIcon2 = new icon({
    iconUrl: marketIcon2,
    iconSize: [38,38] //size icon
    // iconSIze: [1.5,1.5]
  });

  const handleButtonClick = () => {
    setShowMarkers(!showMarkers); // Invierte el estado de showMarkers
  };

  return (
    <>
    <CRow className='margin-row-contenedor'>
    <CCol xs='12' className='d-flex align-items-center contenedor-table-list-titulo'>
          
    <CIcon icon={cilMap} size="xxl" className="height-auto icon-background-orange" style={{'--ci-primary-color': 'white'}}/><h5 className='margin-bottom-h5'><strong>Detalle de Proyecto</strong></h5>
     </CCol>
    <div className="grid-container"> {/* Contenedor principal con CSS Grid */}
    <div className="grid-item map-container" style={{ position: 'relative' }}> {/* Primera cuadrícula superior para el mapa */}
  {/* <CCard className="mb-4"> */}
  <CCard>
    <MapContainer center={[-34.61315, -58.37723]} zoom={13} style={{ height: '300px' }}> 
      <TileLayer
        attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {showMarkers && ( // Condicional para mostrar u ocultar los marcadores
        <>
          { markers.map((market) => (
            <Marker position={position} icon={customIcon}></Marker>
          ))}
          { markers.map((market) => (
            <Marker position={position2} icon={customIcon2}></Marker>
          ))}
        </>
      )}
      {/* <div style={{ position: 'absolute', top: '20px', right: '20px' }}> 
        <button style={{ marginRight: '10px' }}>
          Botón 1
        </button>
        <button style={{ marginRight: '10px' }}>
          Botón 2
        </button>
        <button>
          Botón 3
        </button>
      </div> */}
      {/* <button style={{ position: 'absolute', bottom: '45px', right: '45px' }}>
        <CIcon icon={cilCompress} title="Download file" style={{'--ci-primary-color': 'black'}} />
      </button> */}
    </MapContainer>
  </CCard>
</div>
      {/* Agrega las otras 3 cuadrículas aquí */}
      {/* <MapContainer center={[-34.61315, -58.37723]} zoom={13} style={{ height: '250px' }}> 
      <TileLayer
        attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      { markers.map((market) => (
      <Marker position={position} icon={customIcon}></Marker>
    ))}
    { markers.map((market) => (
      <Marker position={position2} icon={customIcon2}></Marker>
    ))}

    </MapContainer>
    <MapContainer center={[-34.61315, -58.37723]} zoom={13} style={{ height: '250px' }}> 
      <TileLayer
        attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      { markers.map((market) => (
      <Marker position={position} icon={customIcon}></Marker>
    ))}
    { markers.map((market) => (
      <Marker position={position2} icon={customIcon2}></Marker>
    ))}

    </MapContainer> */}
    
    <CCard className='video-container-card'>
    {/* <CCardHeader></CCardHeader> */}
    {/* <div class="video-container"> */}
    {/* <video class="video-grande" src="video-grande.mp4" controls></video> */}
    {/* <div class="videos-pequenos"> */}
    <Widgets /> 
    {/* </div></div> */}
    </CCard>
    </div>
    <div className="grid-container-3">
      <CRow>
      <CCol xs={3} className="letra-contenido">
      <CCard className='width-card-anotaciones'>
        <CRow className='text-center'> {/* Agrega text-center a la fila */}
        <CCol xs={1} className=''></CCol>
          <CCol xs={10} className='rsd'>
            
            <span className="letra-contenido-4"><img src={marketMapaSeñal} width={20} alt="Market Mapa Señal" /></span>
              <span className='letra-contenido-4'>Detencciones</span>
            
          </CCol>
          <CCol xs={1} className=''></CCol>
        </CRow>
        <CRow className='text-center'> {/* Agrega text-center a la fila */}
          <CCol className='text-center rsd2'>
            <span className="letra-contenido-4">592</span>
            <span className='fuente-letra-gris'> /Totales</span>
          </CCol>
        </CRow>
      </CCard>
    </CCol>
      <CCol xs={3} className="letra-contenido">
        <CCard className='width-card-anotaciones'><CRow>
        <CCol className='text-center rsd'>
        <img src={marketSeñal} width={20}></img><span className='letra-contenido-4'>Señales Verticales</span>
          </CCol>
          </CRow>
          <CRow>
        <CCol className='text-center rsd2'>
        <span className="letra-contenido-4">162</span> <span className='fuente-letra-gris'>/Totales</span>
          </CCol>
          </CRow></CCard></CCol>
      <CCol xs={3} className="letra-contenido">
        <CCard className='width-card-anotaciones'>
        <CRow>
        <CCol className='text-center rsd'>
        <img src={marketCartel} width={20}></img><span className='letra-contenido-4'>Señales Horizontales</span>
          </CCol>
          </CRow>
          <CRow>
        <CCol className='text-center rsd2'>
        <span className="letra-contenido-4">163</span> <span className='fuente-letra-gris'>/Totales</span>
          </CCol>
          </CRow></CCard></CCol>
      <CCol xs={3} className="letra-contenido">
        <CCard className='width-card-anotaciones'>
        <CRow>
        <CCol className='text-center rsd'>
        <img src={marketRuta} width={20}></img><span className='letra-contenido-4'>Daños de Pavimento</span>
          </CCol>
          </CRow>
          <CRow>
        <CCol className='text-center rsd2'>
          <span className="letra-contenido-4">267</span>  <span className='fuente-letra-gris'>/Totales</span>
          </CCol>
          </CRow></CCard></CCol>
      </CRow>
    </div>
    <div className="grid-container-2"> {/* Contenedor principal con CSS Grid */}
      <div className="grid-item map-container"> {/* Primera cuadrícula superior para el mapa */}
    <CCard className='video-container-card'>
    <div class="tab-list-container">
    
    <div class="inner-addon right-addon">
      <CRow>
        <CCol xs='10'>
          <CIcon icon={cilMagnifyingGlass} size="xxl" className="glyphicon glyphicon-search" style={{'--ci-primary-color': 'black'}}/>
            {/* <input type="text" class="form-control" placeholder="Buscar proyecto" /> */}
            {/* <CFormLabel htmlFor="anchoCarril"></CFormLabel> */}
            <CFormInput size="sm" id="anchoCarril" placeholder="Buscar proyecto"  style={{ width: '30%' }}/>
            </CCol>
            <CCol xs='2'>
            <CButton className="px-8 btn-search-list" type="submit"><CIcon icon={cilCloudDownload} size="sm" style={{'--ci-primary-color': 'black', 'padding-right': '0.2rem'}}/>Descargar</CButton>
        </CCol>
      </CRow>        
    </div>
    <CTabs activeItemKey={1}>
      <CTabList variant="underline-border" className='tablist-map-component'>
        <CTab className='asb asbd' aria-controls="home-tab-pane" itemKey={1}>Información de Proyecto</CTab>
        <CTab className='asb' aria-controls="profile-tab-pane" itemKey={2}>Señales de Transito</CTab>
        <CTab className='asb' aria-controls="profile-tab-pane" itemKey={3}>Daños de Pavimento</CTab>
      </CTabList>
      <CTabContent>
        <CTabPanel className="py-3 tabpanel-aling-content" aria-labelledby="home-tab-pane" itemKey={1}>
          <CRow>
            <CCol xs='6'>
          
          <CCard className='video-container-card'>
          <CRow>
            
          <CCol xs='12'>
                <CTable borderless className='margin-bottom-table'>
                  <CTableBody>
                    <CTableRow>
                    <CTableDataCell className="quitar-padding-map letra-titulo-3">
                        <h5>Proyecto ID 0001:</h5>
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                    <CTableDataCell className="quitar-padding-map letra-titulo-3">
                        <strong>Relevamiento Autopista</strong>
                      </CTableDataCell>
                      <CTableDataCell className="quitar-padding-map letra-contenido">
                        17/08/2024 15:02:56
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>
                   
              </CCol>
              
              </CRow>
              <hr className='quitar-margin-hr'></hr>
              <CRow>
              
              <CCol xs='12'>
                
                <CTable borderless className='margin-bottom-table'>
                  <CTableBody>
                    <CTableRow>
                    <CTableDataCell className="quitar-padding-map letra-titulo-2">
                    <strong>Fecha de inicio de ejecución:</strong>
                      </CTableDataCell>
                      <CTableDataCell className="quitar-padding-map letra-titulo-2">
                      <strong>Fecha de fin de ejecución:</strong>
                      </CTableDataCell>
                    </CTableRow>
                    <CTableRow>
                    <CTableDataCell className="quitar-padding-map letra-contenido">
                    17/08/2024 15:02:56
                      </CTableDataCell>
                      <CTableDataCell className="quitar-padding-map letra-contenido">
                      17/08/2024 15:02:56
                      </CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                </CTable>  
                
              </CCol>
              
              <CCol xs='12'>
                <CTable borderless className='margin-bottom-table'>
                <CTableBody>
                    <CTableRow>
                    <CTableDataCell className="quitar-padding-with-2 letra-titulo-2">
                    <strong>Cliente:</strong>
                      </CTableDataCell>
                      <CTableDataCell className="quitar-padding-map letra-titulo-2">
                    <strong>Operador:</strong>
                      </CTableDataCell>
                      
                    </CTableRow>
                    <CTableRow>
                    <CTableDataCell className="quitar-padding-map letra-contenido">
                        Empresa XXD
                      </CTableDataCell>
                      <CTableDataCell className="quitar-padding-map letra-contenido">
                        Juan Perez
                      </CTableDataCell>
                      
                    </CTableRow>
                  </CTableBody>
                </CTable> 
              </CCol>
              <CCol xs='12'>
                <CTable borderless className='margin-bottom-table'>
                <CTableBody>
                    <CTableRow>
                    <CTableDataCell className="quitar-padding-map letra-titulo-2">
                    <strong>Descripción:</strong>
                      </CTableDataCell>
                    
                    </CTableRow>
                    <CTableRow>
                    <CTableDataCell className="quitar-padding-map letra-contenido">
                        Empresa XXD Empresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXD
                      </CTableDataCell>
                      
                    </CTableRow>
                  </CTableBody>
                </CTable> 
              </CCol>
            
            
          </CRow>
          <hr></hr>
          </CCard>
          </CCol>
          </CRow>
          
        </CTabPanel>
        <CTabPanel className="py-3" aria-labelledby="profile-tab-pane" itemKey={2}>
        <CCardBody className='contenedor-table-list container-tabla' style={{ width: '955px' }}>
            
        <div style={{ overflow: 'auto', height: '370px', width: '955px' }}>
            <div 
          ref={tableRef} 
          style={{ 
            width: '1500px', // Ancho del contenido de la tabla
            display: 'flex', 
            justifyContent: 'flex-start',
            scrollLeft: scrollOffset // Desplazamiento controlado por JavaScript
          }}
        >
              <CTable striped small align="middle" className="mb-0 ctable-list" hover responsive>
                <CTableHead className='sticky-header'>
                  <CTableRow>
                    <CTableHeaderCell style={{ width: '80px' }}>ID</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Tipo</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Señaletica</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Descripcíon</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Latitud</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Longitud</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Fecha de detección</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Carretera Asociada</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Punto KM</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Sentido</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Frame</CTableHeaderCell>
                    <CTableHeaderCell ></CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {tableExample.map((item, index) => (
                    <CTableRow v-for="item in tableItems" key={index}>
                      <CTableDataCell className="text-center">
                        1
                      </CTableDataCell>
                      <CTableDataCell>
                        <div>{item.user.name}</div>
                        <div className="small text-body-secondary text-nowrap">
                          {/* <span>{item.user.new ? 'New' : 'Recurring'}</span> | Registered:{' '} */}
                          {/* {item.user.registered} */}
                        </div>
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        {/* <CIcon size="xl" icon={item.country.flag} title={item.country.name} /> */}
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="d-flex justify-content-between text-nowrap">
                          {/* <div className="fw-semibold">{item.usage.value}%</div> */}
                          <div className="ms-3">
                            {/* <small className="text-body-secondary">{item.usage.period}</small> */}
                          </div>
                        </div>
                        {/* <CProgress thin color={item.usage.color} value={item.usage.value} /> */}
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        {/* <CIcon size="xl" icon={item.payment.icon} /> */}
                      </CTableDataCell>
                      <CTableDataCell>
                        {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                        {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                      </CTableDataCell>
                      <CTableDataCell>
                        {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                        {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                      </CTableDataCell>
                      <CTableDataCell>
                        {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                        {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                      </CTableDataCell>
                      <CTableDataCell>
                        {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                        {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                      </CTableDataCell>
                      <CTableDataCell>
                        {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                        {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                      </CTableDataCell>
                      <CTableDataCell>
                        {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                        {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                      </CTableDataCell>
                      <CTableDataCell>
                      <CDropdown variant="btn-group">
                        <CDropdownToggle className='dropdown-toggle-list btn-dropdown-list' color="white"><CIcon icon={cilOptions} style={{'--ci-primary-color': 'black'}}/></CDropdownToggle>
                        <CDropdownMenu className='dropdown-menu-list'>
                          <CDropdownItem onClick={() => setVisible(!visible)}>Editar</CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
              </div>
              </div>
            </CCardBody>
        </CTabPanel>
        <CTabPanel className="py-3" aria-labelledby="profile-tab-pane" itemKey={3}>
        <CCardBody className='contenedor-table-list container-tabla' style={{ width: '950px' }}>
            
        <div style={{ overflow: 'auto', height: '370px', width: '950px' }}>
            <div 
          ref={tableRef} 
          style={{ 
            width: '1500px', // Ancho del contenido de la tabla
            display: 'flex', 
            justifyContent: 'flex-start',
            scrollLeft: scrollOffset // Desplazamiento controlado por JavaScript
          }}
        >
              <CTable striped small align="middle" className="mb-0 ctable-list" hover responsive>
                <CTableHead className='sticky-header'>
                  <CTableRow>
                    <CTableHeaderCell style={{ width: '80px' }}>ID</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Tipo</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Severidad</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Area</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Latitud</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Longitud</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Fecha de detección</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Carretera Asociada</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Punto KM</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Sentido</CTableHeaderCell>
                    <CTableHeaderCell style={{ width: '150px' }}>Frame</CTableHeaderCell>
                    <CTableHeaderCell ></CTableHeaderCell>
                  </CTableRow>
                </CTableHead>
                <CTableBody>
                  {tableExample.map((item, index) => (
                    <CTableRow v-for="item in tableItems" key={index}>
                      <CTableDataCell className="text-center">
                        1
                      </CTableDataCell>
                      <CTableDataCell>
                        <div>{item.user.name}</div>
                        <div className="small text-body-secondary text-nowrap">
                          {/* <span>{item.user.new ? 'New' : 'Recurring'}</span> | Registered:{' '} */}
                          {/* {item.user.registered} */}
                        </div>
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        {/* <CIcon size="xl" icon={item.country.flag} title={item.country.name} /> */}
                      </CTableDataCell>
                      <CTableDataCell>
                        <div className="d-flex justify-content-between text-nowrap">
                          {/* <div className="fw-semibold">{item.usage.value}%</div> */}
                          <div className="ms-3">
                            {/* <small className="text-body-secondary">{item.usage.period}</small> */}
                          </div>
                        </div>
                        {/* <CProgress thin color={item.usage.color} value={item.usage.value} /> */}
                      </CTableDataCell>
                      <CTableDataCell className="text-center">
                        {/* <CIcon size="xl" icon={item.payment.icon} /> */}
                      </CTableDataCell>
                      <CTableDataCell>
                        {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                        {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                      </CTableDataCell>
                      <CTableDataCell>
                        {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                        {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                      </CTableDataCell>
                      <CTableDataCell>
                        {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                        {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                      </CTableDataCell>
                      <CTableDataCell>
                        {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                        {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                      </CTableDataCell>
                      <CTableDataCell>
                        {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                        {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                      </CTableDataCell>
                      <CTableDataCell>
                        {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                        {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                      </CTableDataCell>
                      <CTableDataCell>
                      <CDropdown variant="btn-group">
                        <CDropdownToggle className='dropdown-toggle-list btn-dropdown-list' color="white"><CIcon icon={cilOptions} style={{'--ci-primary-color': 'black'}}/></CDropdownToggle>
                        <CDropdownMenu className='dropdown-menu-list'>
                          <CDropdownItem onClick={() => setVisible2(!visible2)}>Editar</CDropdownItem>
                        </CDropdownMenu>
                      </CDropdown>
                      </CTableDataCell>
                    </CTableRow>
                  ))}
                </CTableBody>
              </CTable>
              </div>
              </div>
            </CCardBody>
        </CTabPanel> 
      </CTabContent>
    </CTabs>
    </div>
    </CCard>
    </div>
    </div>
    
    </CRow>
    <CModal
      alignment="center"
      scrollable
      visible={visible}
      onClose={() => setVisible(false)}
      aria-labelledby="VerticallyCenteredExample"
      
    >
      <CModalContent style={{'width': '510px'}}>
      <CModalHeader style={{'--cui-modal-header-border-color': 'white'}}>
        <CModalTitle className='letra-titulo-modal' id="VerticallyCenteredExample">Edición de Señal</CModalTitle>
      </CModalHeader>
      <CModalBody>
        
      <div className="mb-3 letra-contenido-2">
          <CRow>
          <CCol xs={12}>
          <CFormLabel htmlFor="tiposenal">Tipo</CFormLabel>
          <CTooltip className='padding-tooltip'
              content="Señalización Global (CLASS 1)"
              // trigger={['hover', 'focus']}
            >
              <span className="d-inline-block padding-tooltip" tabIndex={0}>
                <CButton className="btn-round-small">i</CButton>
              </span>
            </CTooltip>
          <CFormInput className='letra-contenido-modal-input' size="sm" id="tiposenal" placeholder="Ingrese tipo" value={tipoSenal} onChange={(e) => setTipoSenal(e.target.value)}/>
          </CCol>
          </CRow>
        </div>

        <div className="mb-3 letra-contenido-2">
          <CRow>
          <CCol xs={6}>
          <CFormLabel htmlFor="senaleticadetectada">Señaletica Detectada</CFormLabel>
          <CTooltip className='padding-tooltip'
              content="Señalización Tipo (CLASS 2)"
              // trigger={['hover', 'focus']}
            >
              <span className="d-inline-block padding-tooltip" tabIndex={0}>
                <CButton className="btn-round-small">i</CButton>
              </span>
            </CTooltip>
          <CFormInput className='letra-contenido-modal-input' size="sm" id="senaleticadetectada" placeholder="Ingrese señaletica" value={senaleticaDetectada} onChange={(e) => setSenaleticaDetectada(e.target.value)}/>
            </CCol>
            <CCol xs={6}>
        <CFormSelect size="sm"
                className='letra-contenido-modal-input'
                label="Señaletica Reclasificada"
                value={senaleticaReclasificada}
                onChange={(e) => setSenaleticaReclasificada(e.target.value)}>
                  <option className='fuente-letra-oscura' >Seleccione</option>
                  <option className='fuente-letra-oscura' value="reglamentaria">Reglamentaria</option>
                  <option className='fuente-letra-oscura' value="preventiva">Preventiva</option>
                  <option className='fuente-letra-oscura' value="informativa">Informativa</option>
                  <option className='fuente-letra-oscura' value="otra">Otra</option>
                </CFormSelect>
          </CCol>
            </CRow>
          </div>

          <div className="mb-3 letra-contenido-2">
            <CRow>
            <CCol xs={6}>
            <CFormLabel htmlFor="descripciondetectada">Descripción Detectada</CFormLabel>
            <CTooltip className='padding-tooltip'
              content="Señalización Descripción (CLASS 5)"
              // trigger={['hover', 'focus']}
            >
              <span className="d-inline-block padding-tooltip" tabIndex={0}>
                <CButton className="btn-round-small">i</CButton>
              </span>
            </CTooltip>
            <CFormInput className='letra-contenido-modal-input' size="sm" id="descripciondetectada" placeholder="Ingrese descripcion" value={descripcionDetectada} onChange={(e) => setDescripcionDetectada(e.target.value)}/>
            </CCol>
            <CCol xs={6}>
        <CFormSelect size="sm"
                className='letra-contenido-modal-input'
                label="Descripción Detectada Reclasificada"
                value={descripcionReclasificada}
                onChange={(e) => setDescripcionReclasificada(e.target.value)}>
                  <option className='fuente-letra-oscura' >Seleccione</option>
                  <option className='fuente-letra-oscura' value="reglamentaria">No avanzar</option>
                  <option className='fuente-letra-oscura' value="preventiva">No circular Automóviles</option>
                  <option className='fuente-letra-oscura' value="informativa">Prohibición de cambiar de carril</option>
                  <option className='fuente-letra-oscura' value="otra">Velocidad maxima 20 km/h</option>
                </CFormSelect>
          </CCol>
            </CRow>
          </div>

          <div className="mb-3 letra-contenido-2">
      <CRow>
      <CCol xs={12}>
      <CFormLabel htmlFor="observacionessenal">Observaciones</CFormLabel>
      <CFormTextarea
      className='letra-contenido-modal-input'
      id="observacionessenal"
      placeholder="Escribe comentarios u observaciones"
      value={observacionesSenal} 
      onChange={(e) => setObservacionesSenal(e.target.value)}
      rows={4}
      ></CFormTextarea>
      </CCol>
    
      
      </CRow>
    </div>
        
        <div className="mb-3 letra-contenido-2">
          <CRow>
          <CCol xs={6}>
          <CFormLabel htmlFor="latitudsenal">Latitud</CFormLabel>
          <CFormInput className='letra-contenido-modal-input' size="sm" id="latitudsenal" placeholder="Ingrese latitud" value={latitudSenal} onChange={(e) => setLatitudSenal(e.target.value)}/>
            </CCol>
            <CCol xs={6}>
          <CFormLabel htmlFor="longitudsenal">Longitud</CFormLabel>
          <CFormInput className='letra-contenido-modal-input' size="sm" id="longitudsenal" placeholder="Ingrese longitud" value={longitudSenal} onChange={(e) => setLongitudSenal(e.target.value)}/>
            </CCol>
            </CRow>
          </div>
          <div className="mb-3 letra-contenido-2">
          <CRow>
          <CCol xs={6}>
          <CFormLabel htmlFor="carreteraasociadasenal">Carretera Asociada</CFormLabel>
          <CFormInput className='letra-contenido-modal-input' size="sm" id="carreteraasociadasenal" placeholder="Ingrese carretera" value={carreteraAsociadaSenal} onChange={(e) => setCarreteraAsociadaSenal(e.target.value)}/>
            </CCol>
            <CCol xs={6}>
          <CFormLabel htmlFor="fechadeteccionsenal">Fecha de detección</CFormLabel>
          <CFormInput className='letra-contenido-modal-input' size="sm" id="fechadeteccionsenal" placeholder="Ingrese fecha" value={fechaDeteccionSenal} onChange={(e) => setFechaDeteccionSenal(e.target.value)}/>
            </CCol>
            </CRow>
          </div>

          <div className="mb-3 letra-contenido-2">
          <CRow>
          <CCol xs={6}>
          <CFormLabel htmlFor="puntokmsenal">Punto KM</CFormLabel>
          <CFormInput className='letra-contenido-modal-input' size="sm" id="puntokmsenal" placeholder="Ingrese km" value={puntokmSenal} onChange={(e) => setPuntokmSenal(e.target.value)}/>
            </CCol>
            <CCol xs={6}>
          <CFormLabel htmlFor="sentidosenal">Sentido</CFormLabel>
          <CFormInput className='letra-contenido-modal-input' size="sm" id="sentidosenal" placeholder="Ingrese sentido" value={sentidoSenal} onChange={(e) => setSentidoSenal(e.target.value)}/>
            </CCol>
            </CRow>
          </div>

      </CModalBody>
      <CModalFooter style={{'--cui-modal-footer-border-color': 'white'}}>
        <CButton className='btn-salir-footer' onClick={() => setVisible(false)}>
          Cancelar
        </CButton>
        <CButton className='btn-next-footer'>Guardar</CButton>
      </CModalFooter>
      </CModalContent>
    </CModal>

    <CModal
      alignment="center"
      scrollable
      visible={visible2}
      onClose={() => setVisible2(false)}
      aria-labelledby="VerticallyCenteredExample"
    >
      <CModalContent style={{'width': '510px'}}>
      <CModalHeader style={{'--cui-modal-header-border-color': 'white'}}>
        <CModalTitle className='letra-titulo-modal' id="VerticallyCenteredExample">Edición de Daño</CModalTitle>
      </CModalHeader>
      <CModalBody>

        <div className="mb-3 letra-contenido-2">
          <CRow>
          <CCol xs={6}>
          <CFormLabel htmlFor="tipodano">Tipo</CFormLabel>
          <CFormInput className='letra-contenido-modal-input' size="sm" id="tipodano" placeholder="Ingrese tipo" value={tipoDano} onChange={(e) => setTipoDano(e.target.value)}/>
          </CCol>
          <CCol xs={6}>
          <CFormLabel htmlFor="area">Area</CFormLabel>
          <CFormInput className='letra-contenido-modal-input' size="sm" id="area" placeholder="Ingrese area" value={area} onChange={(e) => setArea(e.target.value)}/>
          </CCol>
          </CRow>
        </div>

        <div className="mb-3 letra-contenido-2">
          <CRow>
          <CCol xs={6}>
          <CFormLabel htmlFor="severidad">Severidad</CFormLabel>
          <CFormInput className='letra-contenido-modal-input' size="sm" id="severidad" placeholder="Ingrese Severidad" value={severidad} onChange={(e) => setSeveridad(e.target.value)}/>
            </CCol>
            <CCol xs={6}>
            <CFormSelect size="sm"
                className='letra-contenido-modal-input'
                label="Severidad Reclasificada"
                value={severidadReclasificada}
                onChange={(e) => setSeveridadReclasificada(e.target.value)}>
                  <option className='fuente-letra-oscura' >Seleccione</option>
                  <option className='fuente-letra-oscura' value="T2">T2</option>
                  <option className='fuente-letra-oscura' value="T4">T4</option>
                  <option className='fuente-letra-oscura' value="T6">T6</option>
                  <option className='fuente-letra-oscura' value="T8">T8</option>
                  <option className='fuente-letra-oscura' value="T10">T10</option>
                </CFormSelect>
          </CCol>
            </CRow>
          </div>

          <div className="mb-3 letra-contenido-2">
      <CRow>
      <CCol xs={12}>
      <CFormLabel htmlFor="observacionesdano">Observaciones</CFormLabel>
      <CFormTextarea
      className='letra-contenido-modal-input'
      id="observacionesdano"
      placeholder="Escribe comentarios u observaciones"
      value={observaciones} 
      onChange={(e) => setObservaciones(e.target.value)}
      rows={4}
      ></CFormTextarea>
      </CCol>
    
      
      </CRow>
    </div>
        
        <div className="mb-3 letra-contenido-2">
          <CRow>
          <CCol xs={6}>
          <CFormLabel htmlFor="latituddano">Latitud</CFormLabel>
          <CFormInput className='letra-contenido-modal-input' size="sm" id="latituddano" placeholder="Ingrese latitud" value={latitudDano} onChange={(e) => setLatitudDano(e.target.value)}/>
            </CCol>
            <CCol xs={6}>
          <CFormLabel htmlFor="longituddano">Longitud</CFormLabel>
          <CFormInput className='letra-contenido-modal-input' size="sm" id="longituddano" placeholder="Ingrese longitud" value={longitudDano} onChange={(e) => setLongitudDano(e.target.value)}/>
            </CCol>
            </CRow>
          </div>
          <div className="mb-3 letra-contenido-2">
          <CRow>
          <CCol xs={6}>
          <CFormLabel htmlFor="carreteraasociadadano">Carretera Asociada</CFormLabel>
          <CFormInput className='letra-contenido-modal-input' size="sm" id="carreteraasociadadano" placeholder="Ingrese carretera" value={carreteraAsociadaDano} onChange={(e) => setCarreteraAsociadaDano(e.target.value)}/>
            </CCol>
            <CCol xs={6}>
          <CFormLabel htmlFor="fechadetecciondano">Fecha de detección</CFormLabel>
          <CFormInput className='letra-contenido-modal-input' size="sm" id="fechadetecciondano" placeholder="Ingrese fecha" value={fechaDeteccionDano} onChange={(e) => setfechaDeteccionDano(e.target.value)}/>
            </CCol>
            </CRow>
          </div>

          <div className="mb-3 letra-contenido-2">
          <CRow>
          <CCol xs={6}>
          <CFormLabel htmlFor="puntokmDano">Punto KM</CFormLabel>
          <CFormInput className='letra-contenido-modal-input' size="sm" id="puntokmDano" placeholder="Ingrese km" value={PuntokmDano} onChange={(e) => setPuntokmDano(e.target.value)}/>
            </CCol>
            <CCol xs={6}>
          <CFormLabel htmlFor="sentidoDano">Sentido</CFormLabel>
          <CFormInput className='letra-contenido-modal-input' size="sm" id="sentidoDano" placeholder="Ingrese sentido" value={sentidoDano} onChange={(e) => setsentidoDano(e.target.value)}/>
            </CCol>
            </CRow>
          </div>

      </CModalBody>
      <CModalFooter style={{'--cui-modal-footer-border-color': 'white'}}>
        <CButton className='btn-salir-footer' onClick={() => setVisible2(false)}>
          Cancelar
        </CButton>
        <CButton className='btn-next-footer'>Guardar</CButton>
      </CModalFooter>
      </CModalContent>
    </CModal>
    </>
  );
}

function Map() {
  const map = useMap();
  useMapEvents({
    click: (e) => {
      console.log('Click en:', e.latlng);
      map.panTo(e.latlng);
    }
  });
  return null;
}

