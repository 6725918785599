import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CIcon } from '@coreui/icons-react';
import axios from 'axios';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { icon } from 'leaflet';
import { useMap } from 'react-leaflet/hooks';
import { useMapEvents } from 'react-leaflet/hooks';
import "leaflet/dist/leaflet.css";
import '../../index.css';
import Widgets from '../widgets/Widgets';
import * as XLSX from 'xlsx';

import {
  CCard,
  CButton,
  CTab,
  CTabs,
  CTabList,
  CTabContent,
  CTabPanel,
  CFormInput,
  CFormLabel,
  CCol,
  CRow,
  CCardBody,
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CFormSelect,
  CModalContent,
  CTooltip,
  CFormTextarea,
  CSpinner,
} from '@coreui/react';

import {
  cilMagnifyingGlass,
  cilMap,
  cilOptions,
  cilCloudDownload,
} from '@coreui/icons'

import avatar1 from '../../assets/images/avatars/1.jpg'
import marketIcon from '../../assets/brand/turn-left.png';
import marketIcon2 from '../../assets/brand/turn-right.png';
import marketRuta from '../../assets/brand/ruta.png';
import marketSeñal from '../../assets/brand/señal.png';
import marketMapaSeñal from '../../assets/brand/mapa-señal.png';
import marketCartel from '../../assets/brand/cartel.png';
import circle from '../../assets/images/circle.png';

export default function DetailIndex({ profile, setProfile }) {
  const [selectedDate, setSelectedDate] = useState(null); // Inicializar con la fecha 
  const [selectedDate2, setSelectedDate2] = useState(null); // Inicializar con la fecha2
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [senaleticaReclasificada, setSenaleticaReclasificada] = useState(null);
  const [descripcionReclasificada, setDescripcionReclasificada] = useState(null);
  const [descripcion, setDescripcion] = useState(null);
  const [severidadReclasificada, setSeveridadReclasificada] = useState(null);
  const [itemIdSenal, setItemIdSenal] = useState(null);
  const [tipoSenal, setTipoSenal] = useState(null);
  const [senaleticaDetectada, setSenaleticaDetectada] = useState(null);
  const [descripcionDetectada, setDescripcionDetectada] = useState(null);
  const [observacionesSenal, setObservacionesSenal] = useState(null);
  const [latitudSenal, setLatitudSenal] = useState(null);
  const [longitudSenal, setLongitudSenal] = useState(null);
  const [carreteraAsociadaSenal, setCarreteraAsociadaSenal] = useState(null);
  const [fechaDeteccionSenal, setFechaDeteccionSenal] = useState(null);
  const [puntokmSenal, setPuntokmSenal] = useState(null);
  const [sentidoSenal, setSentidoSenal] = useState(null);
  const [itemIdDano, setItemIdDano] = useState(null);
  const [tipoDano, setTipoDano] = useState(null);
  const [area, setArea] = useState(null);
  const [severidad, setSeveridad] = useState(null);
  const [observaciones, setObservaciones] = useState(null);
  const [latitudDano, setLatitudDano] = useState(null);
  const [longitudDano, setLongitudDano] = useState(null);
  const [carreteraAsociadaDano, setCarreteraAsociadaDano] = useState(null);
  const [fechaDeteccionDano, setfechaDeteccionDano] = useState(null);
  const [PuntokmDano, setPuntokmDano] = useState(null);
  const [sentidoDano, setsentidoDano] = useState(null);
  const [nombreProyecto1, setNombreProyecto1] = useState(null);
  const [fechaCreacion1, setFechaCreacion1] = useState(null);
  const [fechaInicio1, setFechaInicio1] = useState(null);
  const [fechaFin1, setFechaFin1] = useState(null);
  const [cliente1, setCliente1] = useState(null);
  const [operador1, setOperador1] = useState(null);
  const [descripcion1, setDescripcion1] = useState(null);
  const [buscarProyecto, setBuscarProyecto] = useState(null);
  const [dataProyecto, setDataProyecto] = useState(null);
  const [idProyecto, setidProyecto] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [markersData, setMarkersData] = useState([]);
  const [data, setData] = useState([]);
  const [scrollOffset, setScrollOffset] = useState(0);
  const tableRef = useRef(null); // Referencia para la tabla
  const { id } = useParams();

  const [showMarkers, setShowMarkers] = useState(true); // Estado para controlar la visibilidad de los marcadores
  const position = [-34.61315, -58.37723]
  const position2 = [-34.61615, -58.39723]

  const markers = [
    {
      geocode: [-34.61315, -58.37723],
      popup: "Hello"
    }
  ];

  const customIcon = new icon({
    iconUrl: circle,
    iconSize: [16, 16] //size icon
    // iconSIze: [1.5,1.5]
  });
  const customIcon2 = new icon({
    iconUrl: marketIcon2,
    iconSize: [16, 16] //size icon
    // iconSIze: [1.5,1.5]
  });

  const handleButtonClick = () => {
    setShowMarkers(!showMarkers); // Invierte el estado de showMarkers
  };

  const handleMarkerClick = (markerData) => {
    // Actualiza el estado 'data' con la información del marcador
    console.log('market- ', markerData)
    setData([
      // { value: markerData.confianza, label: markerData.area, tipo: markerData.tipo, id_frame: markerData.id_frame },
      { tipo: markerData.tipo, id_frame: markerData.id_frame, url_frame: markerData.url_frame, tipo_camara: markerData.tipo_camara, name_video: markerData.name_video},
      // Puedes agregar más datos aquí si es necesario
    ]);
  };

  const handleUpdateDanno = async () => {
    // e.preventDefault();
    // if (fechaRelevamiento != null) {
    //   fechaRelevamiento = selectedDate3.toISOString().slice(0, 19).replace('T', ' ');
    // }

    const formData = new FormData();
    formData.append('itemIdDano', itemIdDano);
    formData.append('area', area);
    formData.append('tipoDano', tipoDano);
    formData.append('tipoReclasificacionDano', severidadReclasificada);
    formData.append('severidad', severidad);
    formData.append('observaciones', observaciones);
    formData.append('latitudDano', latitudDano);
    formData.append('longitudDano', longitudDano);

    // formData.append('estadoDrenaje', CarreteraAsociadaDan);
    // formData.append('anchoCalzada', sentidoDano);


    // formData.append('estadoBordeCalzada', estadoBordeCalzada);
    // formData.append('tipoPavimento', tipoPavimento);

    try {
      const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
      // console.log(storedName)
      const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
      // console.log(token)
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/update/detection-damage`, formData, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + token
        }
      });

      console.log('Respuesta del servidor:', response.data);
      setVisible2(false);
      // setShowSuccessToast(true); 
      // navigate('/dashboard'); // Redirige si la data se obtiene correctamente
    } catch (error) {
      console.error('Error al subir el video:', error);
    }
  };

  const handleUpdateSennal = async () => {
    // e.preventDefault();
    // if (fechaRelevamiento != null) {
    //   fechaRelevamiento = selectedDate3.toISOString().slice(0, 19).replace('T', ' ');
    // }

    const formData = new FormData();
    formData.append('itemIdSenal', itemIdSenal);
    formData.append('tipoSenal', tipoSenal);
    formData.append('descripcionReclasificada', descripcionReclasificada);
    // formData.append('severidad', severidad);
    formData.append('observacionesSenal', observacionesSenal);
    formData.append('latitudSenal', latitudSenal);
    formData.append('longitudSenal', longitudSenal);

    // setTipoSenal(dataProyecto.fisuras[id].tipo);
    // setSeveridad(dataProyecto.fisuras[id].severidad);
    // setObservacionesSenal(dataProyecto.fisuras[id].descripcion);
    // setLatitudSenal(dataProyecto.fisuras[id].latitud);
    // setLongitudSenal(dataProyecto.fisuras[id].longitud);

    // formData.append('estadoDrenaje', estadoDrenaje);
    // formData.append('anchoCalzada', anchoCalzada);
    // formData.append('estadoBordeCalzada', estadoBordeCalzada);
    // formData.append('tipoPavimento', tipoPavimento);

    try {
      const tokenData = localStorage.getItem('token'); // Obtiene el objeto JSON
      // console.log(storedName)
      const token = tokenData ? JSON.parse(tokenData).token : null; // Obtiene el token del objeto
      // console.log(token)
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/update/detection-sign`, formData, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
          'Authorization': 'Bearer ' + token
        }
      });

      console.log('Respuesta del servidor:', response.data);
      setVisible(false);
      // setShowSuccessToast(true); 
      // navigate('/dashboard'); // Redirige si la data se obtiene correctamente
    } catch (error) {
      console.error('Error al subir el video:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Muestra un indicador de carga mientras se realiza la solicitud

      try {
        const tokenData = localStorage.getItem('token'); // Obtén el token desde el localStorage
        const token = tokenData ? JSON.parse(tokenData).token : null; // Parsear el token JSON

        if (!token) {
          throw new Error('Token no disponible en localStorage'); // Manejar el caso en el que no haya token
        }

        // Realiza la solicitud a la API con el token en los headers
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/detail-project/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        // Si la respuesta no es correcta, lanza un error
        if (!response.ok) {
          throw new Error(`Error ${response.status}: ${response.statusText}`);
        }

        // Obtén los datos JSON de la respuesta
        const jsonData = await response.json();
        console.log('Datos JSON recibidos:', jsonData); // Imprime los datos recibidos

        // Actualiza el estado de dataProyecto con los datos recibidos
        setDataProyecto(jsonData);
        setMarkersData(jsonData);

        // Guarda los datos en el localStorage si es necesario
        localStorage.setItem('data-proyecto2', JSON.stringify(jsonData));

        console.log('Data proyecto:', dataProyecto);
        console.log('Data proyecto2:', JSON.parse(localStorage.getItem('data-proyecto2')));

      } catch (error) {
        console.error('Error durante la solicitud:', error); // Manejo de errores
        setError(error); // Guarda el error en el estado para mostrarlo en la interfaz
      } finally {
        setIsLoading(false); // Detén el indicador de carga
      }
    };

    fetchData(); // Llama a la función fetchData cuando el componente se monte o cambie el id

    // Este useEffect se ejecutará siempre que cambie `id` y asegurará que los datos se recarguen
  }, [id]);

  useEffect(() => {
    if (dataProyecto) {
      // console.log('Data proyecto nuevo:', dataProyecto.fisuras);
      // Carga información del proyecto
      setidProyecto(dataProyecto.proyecto[0].id);
      setNombreProyecto1(dataProyecto.proyecto[0].nombre);
      setCliente1(dataProyecto.proyecto[0].cliente)
      setOperador1(dataProyecto.proyecto[0].operador)
      setDescripcion1(dataProyecto.proyecto[0].descripcion)
      setFechaCreacion1(dataProyecto.proyecto[0].fecha_creacion);
      setFechaInicio1(dataProyecto.proyecto[0].fecha_inicio);
      setFechaFin1(dataProyecto.proyecto[0].fecha_fin);

      setDataProyecto(dataProyecto);

    }
  }, [dataProyecto]);

  useEffect(() => {
    if (markersData) {
      // console.log('Data proyecto nuevo:', dataProyecto.fisuras);
      setMarkersData(markersData);

    }
  }, [markersData]);

  const handleEditDanno = (index, id) => {
    if (dataProyecto) {
      console.log('index ', index)
      console.log('id ', id)
      setItemIdDano(id); // Almacena el ID antes de abrir el modal
      setArea(dataProyecto.fisuras[index].area);
      setTipoDano(dataProyecto.fisuras[index].tipo);
      setSeveridad(dataProyecto.fisuras[index].severidad);
      setObservaciones(dataProyecto.fisuras[index].descripcion);
      setLatitudDano(dataProyecto.fisuras[index].latitud);
      setLongitudDano(dataProyecto.fisuras[index].longitud);
      setLongitudDano(dataProyecto.fisuras[index].longitud);
      setCarreteraAsociadaDano(dataProyecto.fisuras[index].carretera_asociada);
      setSeveridadReclasificada(dataProyecto.fisuras[index].tipo_reclasificacion);
      setsentidoDano(dataProyecto.fisuras[index].sentido_carril);
      // setSeveridadReclasificada(dataProyecto.fisuras[index].longitud);
      // setCarreteraAsociadaDano(dataProyecto.fisuras[index].carreteraasociada);
      // setfechaDeteccionDano(dataProyecto.fisuras[index].fechadeteccion);
      // setPuntokmDano(dataProyecto.fisuras[index].puntokm);
      // setsentidoDano(dataProyecto.fisuras[index].sentido);

      setVisible2(true);
    }
  };

  const handleEditSennal = (index, id) => {
    if (dataProyecto) {
      console.log('index ', index)
      console.log('id ', id)
      setItemIdSenal(id); // Almacena el ID antes de abrir el modal
      // setArea(dataProyecto.fisuras[index].area);
      setTipoSenal(dataProyecto.fisuras[index].tipo);
      setSeveridad(dataProyecto.fisuras[index].severidad);
      setObservacionesSenal(dataProyecto.fisuras[index].descripcion);
      setLatitudSenal(dataProyecto.fisuras[index].latitud);
      setLongitudSenal(dataProyecto.fisuras[index].longitud);
      // setSeveridadReclasificada(dataProyecto.fisuras[index].longitud);
      // setCarreteraAsociadaDano(dataProyecto.fisuras[index].carreteraasociada);
      // setfechaDeteccionDano(dataProyecto.fisuras[index].fechadeteccion);
      // setPuntokmDano(dataProyecto.fisuras[index].puntokm);
      // setsentidoDano(dataProyecto.fisuras[index].sentido);

      setVisible(true);
    }
  };

  const handleDownload = () => {
    alert('por aca')
    if (dataProyecto) {
      // Crea un objeto de hoja de cálculo
      const worksheet = XLSX.utils.json_to_sheet(dataProyecto.fisuras);

      // Crea un libro de trabajo
      const workbook = XLSX.utils.book_new();

      // Agrega la hoja de cálculo al libro de trabajo
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');

      // Crea un buffer de archivo
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // Crea un enlace de descarga
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      downloadLink.download = 'data-proyecto.xlsx'; // Nombre del archivo de descarga
      document.body.appendChild(downloadLink); // Agrega el enlace al documento
      downloadLink.click(); // Click en el enlace para iniciar la descarga
      document.body.removeChild(downloadLink); // Elimina el enlace del documento
    }
  };

  return (
    <>
      <CRow className='margin-row-contenedor'>
        <CCol xs='12' className='d-flex align-items-center contenedor-table-list-titulo'>

          <CIcon icon={cilMap} size="xxl" className="height-auto icon-background-orange" style={{ '--ci-primary-color': 'white' }} /><h5 className='margin-bottom-h5'><strong>Detalle de Proyecto</strong></h5>
        </CCol>
        <div className="grid-container"> {/* Contenedor principal con CSS Grid */}
          <div className="grid-item map-container" style={{ position: 'relative' }}> {/* Primera cuadrícula superior para el mapa */}
            {/* <CCard className="mb-4"> */}
            <CCard>
              <MapContainer center={[-34.61315, -58.37723]} zoom={13} style={{ height: '300px' }}>
                <TileLayer
                  attribution='© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {markersData && markersData.senales &&
                  markersData.senales.map((markerData1) => (
                    <Marker
                      key={markerData1.id}
                      position={[parseFloat(markerData1.latitud), parseFloat(markerData1.longitud)]}
                      icon={customIcon}
                      eventHandlers={{
                        click: () => handleMarkerClick(markerData1) // Asegúrate de que la función se llama correctamente
                      }}
                    >
                      <Popup>
                        <div>
                          <strong>Area:</strong> {markerData1.area}<br />
                          <strong>Confianza:</strong> {markerData1.confianza}<br />
                          {/* Agrega otros campos según sea necesario */}
                        </div>
                      </Popup>
                    </Marker>
                  ))}
              </MapContainer>
            </CCard>
          </div>

          <CCard className='video-container-card'>

            <Widgets data={data} />

          </CCard>
        </div>
        <div className="grid-container-4-cards">
          <div className="card-4-cards">
            <div className="grid-container-3-4-cards">
              <CRow>
                <CCol xs={3} className="letra-contenido">
                  <CCard className='width-card-anotaciones'>
                    <CRow className='text-center'> {/* Agrega text-center a la fila */}
                      <CCol xs={1} className=''></CCol>
                      <CCol xs={10} className='rsd'>

                        <span className="letra-contenido-4"><img src={marketMapaSeñal} width={20} alt="Market Mapa Señal" /></span>
                        <span className='letra-contenido-4 ml-2'>Detencciones</span>

                      </CCol>
                      <CCol xs={1} className=''></CCol>
                    </CRow>
                    <CRow className='text-center'> {/* Agrega text-center a la fila */}
                      <CCol className='text-center rsd2'>
                        <span className="letra-contenido-4">592</span>
                        <span className='fuente-letra-gris ml-2'> /Totales</span>
                      </CCol>
                    </CRow>
                  </CCard>
                </CCol>
                <CCol xs={3} className="letra-contenido">
                  <CCard className='width-card-anotaciones'><CRow>
                    <CCol className='text-center rsd'>
                      <img src={marketSeñal} width={20}></img><span className='letra-contenido-4 ml-2'>Señales Verticales</span>
                    </CCol>
                  </CRow>
                    <CRow>
                      <CCol className='text-center rsd2'>
                        <span className="letra-contenido-4">162</span> <span className='fuente-letra-gris ml-2'>/Totales</span>
                      </CCol>
                    </CRow></CCard></CCol>
                <CCol xs={3} className="letra-contenido">
                  <CCard className='width-card-anotaciones'>
                    <CRow>
                      <CCol className='text-center rsd'>
                        <img src={marketCartel} width={20}></img><span className='letra-contenido-4 ml-2'>Señales Horizontales</span>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol className='text-center rsd2'>
                        <span className="letra-contenido-4">163</span> <span className='fuente-letra-gris ml-2'>/Totales</span>
                      </CCol>
                    </CRow></CCard></CCol>
                <CCol xs={3} className="letra-contenido">
                  <CCard className='width-card-anotaciones'>
                    <CRow>
                      <CCol className='text-center rsd'>
                        <img src={marketRuta} width={20}></img><span className='letra-contenido-4 ml-2'>Daños de Pavimento</span>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol className='text-center rsd2'>
                        <span className="letra-contenido-4">{dataProyecto && dataProyecto.fisuras ? dataProyecto.fisuras.length : 0}</span>  <span className='fuente-letra-gris ml-2'>/Totales</span>
                      </CCol>
                    </CRow>
                  </CCard>
                </CCol>
              </CRow>
            </div>
          </div>
        </div>
        <div className="grid-container-2"> {/* Contenedor principal con CSS Grid */}
          <div className="grid-item map-container"> {/* Primera cuadrícula superior para el mapa */}
            <CCard className='video-container-card'>
              <div class="tab-list-container">

                <div class="inner-addon right-addon">
                  <CRow>
                    <CCol xs='10'>
                      <CIcon icon={cilMagnifyingGlass} size="xxl" className="glyphicon glyphicon-search" style={{ '--ci-primary-color': 'black' }} />
                      {/* <input type="text" class="form-control" placeholder="Buscar proyecto" /> */}
                      {/* <CFormLabel htmlFor="anchoCarril"></CFormLabel> */}
                      <CFormInput size="sm" id="anchoCarril" placeholder="Buscar proyecto" style={{ width: '30%' }} value={buscarProyecto} onChange={(e) => setBuscarProyecto(e.target.value)} />
                    </CCol>
                    <CCol xs='2'>
                      <CButton className="px-8 btn-search-list" type="submit" onClick={handleDownload}><CIcon icon={cilCloudDownload} size="sm" style={{ '--ci-primary-color': 'black', 'padding-right': '0.2rem' }} 
                      />Descargar</CButton>
                    </CCol>
                  </CRow>
                </div>
                <CTabs activeItemKey={1}>
                  <CTabList variant="underline-border" className='tablist-map-component'>
                    <CTab className='asb asbd' aria-controls="home-tab-pane" itemKey={1}>Información de Proyecto</CTab>
                    <CTab className='asb' aria-controls="profile-tab-pane" itemKey={2}>Señales de Transito</CTab>
                    <CTab className='asb' aria-controls="profile-tab-pane" itemKey={3}>Daños de Pavimento</CTab>
                  </CTabList>
                  <CTabContent>
                    <CTabPanel className="py-3 tabpanel-aling-content" aria-labelledby="home-tab-pane" itemKey={1}>
                      <CRow>
                        <CCol xs='6'>

                          <CCard className='video-container-card'>
                            <CRow>

                              <CCol xs='12'>
                                <CTable borderless className='margin-bottom-table'>
                                  <CTableBody>
                                    <CTableRow>
                                      <CTableDataCell className="quitar-padding-map letra-titulo-3">
                                        <h5>Proyecto ID {id}:</h5>
                                      </CTableDataCell>
                                    </CTableRow>
                                    <CTableRow>
                                      <CTableDataCell className="quitar-padding-map letra-titulo-3">
                                        {/* <strong>Relevamiento Autopista</strong> */}
                                        <strong>{nombreProyecto1}</strong>
                                      </CTableDataCell>
                                      <CTableDataCell className="quitar-padding-map letra-contenido">
                                        {/* 17/08/2024 15:02:56 */}
                                        {fechaCreacion1}
                                      </CTableDataCell>
                                    </CTableRow>
                                  </CTableBody>
                                </CTable>

                              </CCol>

                            </CRow>
                            <hr className='quitar-margin-hr'></hr>
                            <CRow>

                              <CCol xs='12'>

                                <CTable borderless className='margin-bottom-table'>
                                  <CTableBody>
                                    <CTableRow>
                                      <CTableDataCell className="quitar-padding-map letra-titulo-2">
                                        <strong>Fecha de inicio de ejecución:</strong>
                                      </CTableDataCell>
                                      <CTableDataCell className="quitar-padding-map letra-titulo-2">
                                        <strong>Fecha de fin de ejecución:</strong>
                                      </CTableDataCell>
                                    </CTableRow>
                                    <CTableRow>
                                      <CTableDataCell className="quitar-padding-map letra-contenido">
                                        {/* 17/08/2024 15:02:56 */}
                                        {fechaInicio1}
                                      </CTableDataCell>
                                      <CTableDataCell className="quitar-padding-map letra-contenido">
                                        {/* 17/08/2024 15:02:56 */}
                                        {fechaFin1}
                                      </CTableDataCell>
                                    </CTableRow>
                                  </CTableBody>
                                </CTable>

                              </CCol>

                              <CCol xs='12'>
                                <CTable borderless className='margin-bottom-table'>
                                  <CTableBody>
                                    <CTableRow>
                                      <CTableDataCell className="quitar-padding-with-2 letra-titulo-2">
                                        <strong>Cliente:</strong>
                                      </CTableDataCell>
                                      <CTableDataCell className="quitar-padding-map letra-titulo-2">
                                        {/* <strong>Operador:</strong> */}
                                        <strong>Operador:</strong>
                                      </CTableDataCell>

                                    </CTableRow>
                                    <CTableRow>
                                      <CTableDataCell className="quitar-padding-map letra-contenido">
                                        {/* Empresa XXD */}
                                        {cliente1}
                                      </CTableDataCell>
                                      <CTableDataCell className="quitar-padding-map letra-contenido">
                                        {operador1}
                                        {/* Juan Perez */}
                                      </CTableDataCell>

                                    </CTableRow>
                                  </CTableBody>
                                </CTable>
                              </CCol>
                              <CCol xs='12'>
                                <CTable borderless className='margin-bottom-table'>
                                  <CTableBody>
                                    <CTableRow>
                                      <CTableDataCell className="quitar-padding-map letra-titulo-2">
                                        <strong>Descripción:</strong>
                                      </CTableDataCell>

                                    </CTableRow>
                                    <CTableRow>
                                      <CTableDataCell className="quitar-padding-map letra-contenido">
                                        {/* Empresa XXD Empresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXDEmpresa XXD */}
                                        {descripcion1}
                                      </CTableDataCell>

                                    </CTableRow>
                                  </CTableBody>
                                </CTable>
                              </CCol>


                            </CRow>
                            <hr></hr>
                          </CCard>
                        </CCol>
                      </CRow>

                    </CTabPanel>
                    <CTabPanel className="py-3" aria-labelledby="profile-tab-pane" itemKey={2}>
                      <CCardBody className='contenedor-table-list container-tabla letra-contenido-2'>

                        <div className='contenedor-scroll-tabla-list' style={{ overflowy: 'auto', height: '370px' }}>
                          {/* <div 
          ref={tableRef} 
          style={{ 
            width: '1500px', // Ancho del contenido de la tabla
            display: 'flex', 
            justifyContent: 'flex-start',
            scrollLeft: scrollOffset // Desplazamiento controlado por JavaScript
          }}
        > */}
                          <CTable striped small align="middle" className="mb-0 ctable-list" hover responsive style={{ width: '100%' }}>
                            <CTableHead className='letra-contenido-2 sticky-header'>
                              <CTableRow>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>ID</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Tipo</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Señaletica</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Descripcíon</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Latitud</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Longitud</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Fecha detección</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Carretera asociada</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Punto KM</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Sentido</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Frame</CTableHeaderCell>
                                <CTableHeaderCell ></CTableHeaderCell>
                              </CTableRow>
                            </CTableHead>
                            <CTableBody className='letra-contenido-3'>
                              {dataProyecto && dataProyecto.fisuras ? (

                                dataProyecto.fisuras.filter((item) => {
                                  return (
                                    (buscarProyecto === null || item.severidad.toLowerCase().includes(buscarProyecto.toLowerCase()))
                                  );
                                }).map((item, index) => (
                                  <CTableRow v-for="item in tableItems" key={index}>
                                    <CTableDataCell className="text-center">
                                      <div>{item.id}</div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      <div>{item.tipo}</div>
                                      <div className="small text-body-secondary text-nowrap">
                                        {/* <span>{item.user.new ? 'New' : 'Recurring'}</span> | Registered:{' '} */}
                                        {/* {item.user.registered} */}
                                      </div>
                                    </CTableDataCell>
                                    <CTableDataCell className="text-center">
                                      {/* <CIcon size="xl" icon={item.country.flag} title={item.country.name} /> */}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      <div className="d-flex justify-content-between text-nowrap">
                                        {/* <div className="fw-semibold">{item.usage.value}%</div> */}
                                        <div className="ms-3">
                                          {/* <small className="text-body-secondary">{item.usage.period}</small> */}
                                        </div>
                                      </div>
                                      {/* <CProgress thin color={item.usage.color} value={item.usage.value} /> */}
                                    </CTableDataCell>
                                    <CTableDataCell className="text-center">
                                      {/* <CIcon size="xl" icon={item.payment.icon} /> */}
                                      <div>{item.latitud}</div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                      <div>{item.longitud}</div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      <CDropdown variant="btn-group">
                                        <CDropdownToggle className='dropdown-toggle-list btn-dropdown-list' color="white"><CIcon icon={cilOptions} style={{ '--ci-primary-color': 'black' }} /></CDropdownToggle>
                                        <CDropdownMenu className='dropdown-menu-list'>
                                          <CDropdownItem className="cursor-pointer" onClick={() => handleEditSennal(index, item.id)}>Editar</CDropdownItem>
                                        </CDropdownMenu>
                                      </CDropdown>
                                    </CTableDataCell>
                                  </CTableRow>
                                ))

                              ) : (
                                <div>Cargando datos...</div> // Muestra un indicador de carga
                              )}
                            </CTableBody>
                          </CTable>
                        </div>
                        {/* </div> */}
                      </CCardBody>
                    </CTabPanel>
                    <CTabPanel className="py-3" aria-labelledby="profile-tab-pane" itemKey={3}>
                      <CCardBody className='contenedor-table-list container-tabla letra-contenido-2'>

                        <div className='contenedor-scroll-tabla-list' style={{ overflowy: 'auto', height: '370px' }}>
                          {/* <div 
          ref={tableRef} 
          style={{ 
            width: '1500px', // Ancho del contenido de la tabla
            display: 'flex', 
            justifyContent: 'flex-start',
            scrollLeft: scrollOffset // Desplazamiento controlado por JavaScript
          }}
        > */}
                          <CTable striped small align="middle" className="mb-0 ctable-list" hover responsive style={{ width: '100%' }}>
                            <CTableHead className='letra-contenido-2 sticky-header'>
                              <CTableRow>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>ID</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Tipo</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Severidad</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Area</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Latitud</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Longitud</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Fecha detección</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Carretera asociada</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Punto KM</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Sentido</CTableHeaderCell>
                                <CTableHeaderCell className='letra-contenido-2 fgh'>Frame</CTableHeaderCell>
                                <CTableHeaderCell ></CTableHeaderCell>
                              </CTableRow>
                            </CTableHead>
                            <CTableBody className='letra-contenido-3'>
                              {dataProyecto && dataProyecto.fisuras ? (

                                dataProyecto.fisuras.filter((item) => {
                                  return (
                                    (buscarProyecto === null || item.severidad.toLowerCase().includes(buscarProyecto.toLowerCase()))
                                  );
                                }).map((item, index) => (

                                  // {/* {dataProyecto.fisuras.map((item, index) => ( */}
                                  <CTableRow v-for="item in tableItems" key={index}>
                                    <CTableDataCell className="text-center">
                                      <div>{item.id}</div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div>{item.tipo}</div> */}
                                      <div>{item.tipo}</div>
                                      <div className="small text-body-secondary text-nowrap">
                                        {/* <span>{item.user.new ? 'New' : 'Recurring'}</span> | Registered:{' '} */}
                                        {/* {item.user.registered} */}
                                      </div>
                                    </CTableDataCell>
                                    <CTableDataCell className="text-center">
                                      {/* <CIcon size="xl" icon={item.country.flag} title={item.country.name} /> */}
                                      <div>{item.severidad}</div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div className="d-flex justify-content-between text-nowrap"> */}
                                      {/* <div className="fw-semibold">{item.usage.value}%</div> */}
                                      <div>{item.area}</div>
                                      {/* </div> */}
                                      {/* <CProgress thin color={item.usage.color} value={item.usage.value} /> */}
                                    </CTableDataCell>
                                    <CTableDataCell className="text-center">
                                      {/* <CIcon size="xl" icon={item.payment.icon} /> */}
                                      <div>{item.latitud}</div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                      <div>{item.longitud}</div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      <div>{item.carretera_asociada}</div>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      <div>{item.sentido_carril}</div>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}

                                    </CTableDataCell>
                                    <CTableDataCell>
                                      {/* <div className="small text-body-secondary text-nowrap">Last login</div> */}
                                      {/* <div className="fw-semibold text-nowrap">{item.activity}</div> */}
                                      <div>{item.id_frame}</div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                      <CDropdown variant="btn-group">
                                        <CDropdownToggle className='dropdown-toggle-list btn-dropdown-list' color="white"><CIcon icon={cilOptions} style={{ '--ci-primary-color': 'black' }} /></CDropdownToggle>
                                        <CDropdownMenu className='dropdown-menu-list'>
                                          <CDropdownItem className="cursor-pointer" onClick={() => handleEditDanno(index, item.id)}>Editar</CDropdownItem>
                                        </CDropdownMenu>
                                      </CDropdown>
                                    </CTableDataCell>
                                  </CTableRow>
                                ))

                              ) : (
                                <div>Cargando datos...</div> // Muestra un indicador de carga
                              )}
                            </CTableBody>
                          </CTable>
                        </div>
                        {/* </div> */}
                      </CCardBody>
                    </CTabPanel>
                  </CTabContent>
                </CTabs>
              </div>
            </CCard>
          </div>
        </div>

      </CRow>
      <CModal
        alignment="center"
        scrollable
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="VerticallyCenteredExample"

      >
        <CModalContent>
          <CModalHeader style={{ '--cui-modal-header-border-color': 'white' }}>
            <CModalTitle className='letra-titulo-modal' id="VerticallyCenteredExample">Edición de Señal</CModalTitle>
          </CModalHeader>
          <CModalBody>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={12}>
                  <CFormLabel htmlFor="tiposenal">Tipo</CFormLabel>
                  <CTooltip className='padding-tooltip'
                    content="Señalización Global (CLASS 1)"
                  // trigger={['hover', 'focus']}
                  >
                    <span className="d-inline-block padding-tooltip" tabIndex={0}>
                      <CButton className="btn-round-small">i</CButton>
                    </span>
                  </CTooltip>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="tiposenal" placeholder="Ingrese tipo" value={tipoSenal} onChange={(e) => setTipoSenal(e.target.value)} />
                </CCol>
              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="senaleticadetectada">Señaletica Detectada</CFormLabel>
                  <CTooltip className='padding-tooltip'
                    content="Señalización Tipo (CLASS 2)"
                  // trigger={['hover', 'focus']}
                  >
                    <span className="d-inline-block padding-tooltip" tabIndex={0}>
                      <CButton className="btn-round-small">i</CButton>
                    </span>
                  </CTooltip>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="senaleticadetectada" placeholder="Ingrese señaletica" value={senaleticaDetectada} onChange={(e) => setSenaleticaDetectada(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormSelect size="sm"
                    className='letra-contenido-modal-input'
                    label="Señaletica Reclasificada"
                    value={senaleticaReclasificada}
                    onChange={(e) => setSenaleticaReclasificada(e.target.value)}>
                    <option className='fuente-letra-oscura' >Seleccione</option>
                    <option className='fuente-letra-oscura' value="reglamentaria">Reglamentaria</option>
                    <option className='fuente-letra-oscura' value="preventiva">Preventiva</option>
                    <option className='fuente-letra-oscura' value="informativa">Informativa</option>
                    <option className='fuente-letra-oscura' value="otra">Otra</option>
                  </CFormSelect>
                </CCol>
              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="descripciondetectada">Descripción Detectada</CFormLabel>
                  <CTooltip className='padding-tooltip'
                    content="Señalización Descripción (CLASS 5)"
                  // trigger={['hover', 'focus']}
                  >
                    <span className="d-inline-block padding-tooltip" tabIndex={0}>
                      <CButton className="btn-round-small">i</CButton>
                    </span>
                  </CTooltip>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="descripciondetectada" placeholder="Ingrese descripcion" value={descripcionDetectada} onChange={(e) => setDescripcionDetectada(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormSelect size="sm"
                    className='letra-contenido-modal-input'
                    label="Descripción Detectada Reclasificada"
                    value={descripcionReclasificada}
                    onChange={(e) => setDescripcionReclasificada(e.target.value)}>
                    <option className='fuente-letra-oscura' >Seleccione</option>
                    <option className='fuente-letra-oscura' value="reglamentaria">No avanzar</option>
                    <option className='fuente-letra-oscura' value="preventiva">No circular Automóviles</option>
                    <option className='fuente-letra-oscura' value="informativa">Prohibición de cambiar de carril</option>
                    <option className='fuente-letra-oscura' value="otra">Velocidad maxima 20 km/h</option>
                  </CFormSelect>
                </CCol>
              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={12}>
                  <CFormLabel htmlFor="observacionessenal">Observaciones</CFormLabel>
                  <CFormTextarea
                    className='letra-contenido-modal-input'
                    id="observacionessenal"
                    placeholder="Escribe comentarios u observaciones"
                    value={observacionesSenal}
                    onChange={(e) => setObservacionesSenal(e.target.value)}
                    rows={4}
                  ></CFormTextarea>
                </CCol>


              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="latitudsenal">Latitud</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="latitudsenal" placeholder="Ingrese latitud" value={latitudSenal} onChange={(e) => setLatitudSenal(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="longitudsenal">Longitud</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="longitudsenal" placeholder="Ingrese longitud" value={longitudSenal} onChange={(e) => setLongitudSenal(e.target.value)} />
                </CCol>
              </CRow>
            </div>
            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="carreteraasociadasenal">Carretera asociada</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="carreteraasociadasenal" placeholder="Ingrese carretera" value={carreteraAsociadaSenal} onChange={(e) => setCarreteraAsociadaSenal(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="fechadeteccionsenal">Fecha de detección</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="fechadeteccionsenal" placeholder="Ingrese fecha" value={fechaDeteccionSenal} onChange={(e) => setFechaDeteccionSenal(e.target.value)} />
                </CCol>
              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="puntokmsenal">Punto KM</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="puntokmsenal" placeholder="Ingrese km" value={puntokmSenal} onChange={(e) => setPuntokmSenal(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="sentidosenal">Sentido</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="sentidosenal" placeholder="Ingrese sentido" value={sentidoSenal} onChange={(e) => setSentidoSenal(e.target.value)} />
                </CCol>
              </CRow>
            </div>

          </CModalBody>
          <CModalFooter style={{ '--cui-modal-footer-border-color': 'white' }}>
            <CButton className='btn-salir-footer' onClick={() => setVisible(false)}>
              Cancelar
            </CButton>
            <CButton className='btn-next-footer' onClick={() => handleUpdateSennal()}>Guardar</CButton>
          </CModalFooter>
        </CModalContent>
      </CModal>

      <CModal
        alignment="center"
        scrollable
        visible={visible2}
        onClose={() => setVisible2(false)}
        aria-labelledby="VerticallyCenteredExample"
      >
        <CModalContent >
          <CModalHeader style={{ '--cui-modal-header-border-color': 'white' }}>
            <CModalTitle className='letra-titulo-modal' id="VerticallyCenteredExample">Edición de Daño</CModalTitle>
          </CModalHeader>
          <CModalBody>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="tipodano">Tipo</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="tipodano" placeholder="Ingrese tipo" value={tipoDano} onChange={(e) => setTipoDano(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="area">Area</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="area" placeholder="Ingrese area" value={area} onChange={(e) => setArea(e.target.value)} />
                </CCol>
              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="severidad">Severidad</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="severidad" placeholder="Ingrese Severidad" value={severidad} onChange={(e) => setSeveridad(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormSelect size="sm"
                    className='letra-contenido-modal-input'
                    label="Severidad Reclasificada"
                    value={severidadReclasificada}
                    onChange={(e) => setSeveridadReclasificada(e.target.value)}>
                    <option className='fuente-letra-oscura' >Seleccione</option>
                    <option className='fuente-letra-oscura' value="T02">T2</option>
                    <option className='fuente-letra-oscura' value="T04">T4</option>
                    <option className='fuente-letra-oscura' value="T06">T6</option>
                    <option className='fuente-letra-oscura' value="T08">T8</option>
                    <option className='fuente-letra-oscura' value="T10">T10</option>
                  </CFormSelect>
                </CCol>
              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={12}>
                  <CFormLabel htmlFor="observacionesdano">Observaciones</CFormLabel>
                  <CFormTextarea
                    className='letra-contenido-modal-input'
                    id="observacionesdano"
                    placeholder="Escribe comentarios u observaciones"
                    value={observaciones}
                    onChange={(e) => setObservaciones(e.target.value)}
                    rows={4}
                  ></CFormTextarea>
                </CCol>


              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="latituddano">Latitud</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="latituddano" placeholder="Ingrese latitud" value={latitudDano} onChange={(e) => setLatitudDano(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="longituddano">Longitud</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="longituddano" placeholder="Ingrese longitud" value={longitudDano} onChange={(e) => setLongitudDano(e.target.value)} />
                </CCol>
              </CRow>
            </div>
            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="carreteraasociadadano">Carretera asociada</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="carreteraasociadadano" placeholder="Ingrese carretera" value={carreteraAsociadaDano} onChange={(e) => setCarreteraAsociadaDano(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="fechadetecciondano">Fecha de detección</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="fechadetecciondano" placeholder="Ingrese fecha" value={fechaDeteccionDano} onChange={(e) => setfechaDeteccionDano(e.target.value)} />
                </CCol>
              </CRow>
            </div>

            <div className="mb-3 letra-contenido-2">
              <CRow>
                <CCol xs={6}>
                  <CFormLabel htmlFor="puntokmDano">Punto KM</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="puntokmDano" placeholder="Ingrese km" value={PuntokmDano} onChange={(e) => setPuntokmDano(e.target.value)} />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="sentidoDano">Sentido</CFormLabel>
                  <CFormInput className='letra-contenido-modal-input' size="sm" id="sentidoDano" placeholder="Ingrese sentido" value={sentidoDano} onChange={(e) => setsentidoDano(e.target.value)} />
                </CCol>
              </CRow>
            </div>

          </CModalBody>
          <CModalFooter style={{ '--cui-modal-footer-border-color': 'white' }}>
            <CButton className='btn-salir-footer' onClick={() => setVisible2(false)}>Cancelar</CButton>
            <CButton className='btn-next-footer' onClick={() => handleUpdateDanno()}>Guardar</CButton>
          </CModalFooter>
        </CModalContent>
      </CModal>
      {isLoading && ( // Mostrar el spinner solo si isLoading es true
        <div className="loading-overlay">
          <CSpinner color="primary" />
        </div>
      )}
    </>
  );
}

function Map() {
  const map = useMap();
  useMapEvents({
    click: (e) => {
      console.log('Click en:', e.latlng);
      map.panTo(e.latlng);
    }
  });
  return null;
}

